import React from 'react';
import { useState } from 'react';
import {
  Flex, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  CircularProgress,
} from '@chakra-ui/react';
import { useModalContext } from 'context/modalContext';

// eslint-disable-next-line no-undef
const OutboardLayout = ({ children }) => {
  const { dispatch: modalDispatch } = useModalContext();
  const { state: modalState } = useModalContext();
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  return (
    <div style={{
      minHeight: '100vh',
      padding: '0 0.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'}}>
      <div style={{
        padding: '5rem 0',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        {children}
      </div>
      <footer style={{
        width: '100%',
        height: '100px',
        borderTop: '1px solid #eaeaea',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        &#169; {currentYear} Semar Sentinel | Subsidiary of Steinberg Protocol | All rights Reserved
      </footer>
      <Modal isOpen={showModal} onClose={closeModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalState.modal.title}</ModalHeader>
          {
            modalState.modal.status !== 'LOADING'
          && <ModalCloseButton />
          }
          <ModalBody>
            {
              modalState.modal.status === 'LOADING'
            && (
            <Flex alignItems="center" justifyContent="center" mb="8">
              <CircularProgress isIndeterminate />
            </Flex>
            )

            }
            <Text>
              {modalState.modal.message}
            </Text>
          </ModalBody>

          <ModalFooter>
            {
              modalState.modal.status !== 'LOADING'
            && (
            <Button colorScheme="blue" onClick={closeModal}>
              Close
            </Button>
            )
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OutboardLayout;
