import React from 'react';

import {
  Flex, Text, CloseButton, Box, Badge,
} from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/color-mode';
import { motion } from "framer-motion";


function AssetBoxMap(props) {
  let { asset, resetCallBack: resetDetails, disableDragMap, enableDragMap } = props;
  const { colorMode } = useColorMode();

  return (
    <Box
      as={motion.div}
      bg={colorMode === 'light' ? 'white' : '#171923'}
      textAlign="start"
      borderRadius="lg"
      boxShadow="lg"
      w="sm"
      drag
      dragMomentum={false}
      onMouseDown={() => { disableDragMap() }}
      onMouseUp={()=> {enableDragMap()}}
      style={{
        position: 'absolute', bottom: '150%', maxWidth: '250px', cursor: 'default'
      }}
      p={3}
      _after={{
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        marginLeft: '-5px',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: `${colorMode === 'light' ? 'white' : '#171923'} transparent transparent transparent`,
      }}
    >
      <Flex alignItems="end">
        <Text fontWeight="semibold" fontSize="14">
          {asset.assetName}
        </Text>
        <CloseButton ml="auto" size="sm" onClick={resetDetails} />
      </Flex>
      <Badge borderRadius="full" px="2" colorScheme="teal" mt={2}>
        Asset
      </Badge>
      <Text my={3} fontSize="12">
        {asset.province}
      </Text>
      <Text>
        Longitude:
        {' '}
        {asset.longitude}
      </Text>
      <Text>
        Latitude:
        {' '}
        {asset.latitude}
      </Text>
    </Box>
  );
}

export default AssetBoxMap;
