import React from 'react';

import {
  FormControl, FormLabel, Input, FormErrorMessage, Textarea,
} from '@chakra-ui/react';

function TextField({
  form, field, id, labelName, placeholder, type,
}) {
  return (
    // eslint-disable-next-line no-bitwise
    <FormControl isInvalid={(form.errors[id] && form.touched[id])} mt={4}>
      <FormLabel htmlFor={id}>{labelName}</FormLabel>
      <Textarea
        {...field}
        id={id}
        placeholder={placeholder}
        type={type || 'textarea'}
        minH="130px"
      />
      <FormErrorMessage>
        {labelName}
        {' '}
        {form.errors[id]}
      </FormErrorMessage>
    </FormControl>
  );
}

export default TextField;
