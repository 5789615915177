import React from "react";
import { useEffect, useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { login } from "api/auth";
import SEO from "components/SEO";
import ModalForgetPassword from "components/inboard/user/ModalForgetPassword";
import { useColorMode } from "@chakra-ui/color-mode";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const Login = () => {
  const router = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [errors, setErrors] = useState("");

  useEffect(() => {
    const cookie = Cookies.get("user");
    if (cookie) {
      router.push("/dashboard/profile");
    }
  }, []);

  const handleChange = (e) => {
    setErrors("");
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleShowClick = () => setShowPassword(!showPassword);
  const handleLogin = async (event) => {
    event.preventDefault();
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(credentials.email).toLowerCase())) {
      setErrors("Email must be a valid email");
      return;
    }
    if (credentials.email === "") {
      setErrors("Email cannot be empty");
      return;
    }

    if (credentials.password === "") {
      setErrors("Password cannot be empty");
      return;
    }
    setIsLoggingIn(true);
    try {
      const { data } = await login(credentials);
      const { token } = data.data;
      Cookies.set("user", token, {
        path: "/",
        expires: new Date(new Date().getTime() + 86400 * 1000),
      });
      setIsLoggingIn(false);
      router.push("/dashboard/profile");
    } catch (err) {
      setErrors("Invalid email or password");
      setIsLoggingIn(false);
      console.log(err);
    }
  };
  const { colorMode } = useColorMode();
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center">
      <SEO title="Asset Monitoring" />
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: "-1",
          }}
        >
          <source src="https://semar-sentinel.com/wp-content/uploads/2019/06/057542129-digital-map_prores.mp4" />
        </video>
        <Box
          w="xl"
          borderWidth="1px"
          borderRadius="3xl"
          bgColor={colorMode === "light" ? "whiteAlpha.700" : "blackAlpha.700"}
        >
          <Divider my="3" width="1px" />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Heading color={colorMode === "light" ? "#18587c" : "white"}>
              Semar Sentinel
            </Heading>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              fontSize="22"
              color={colorMode === "light" ? "#18587c" : "white"}
            >
              Asset Monitoring
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box w="sm" mb={10}>
              <Divider my="3" width="15" />
              <form size="md">
                <Stack spacing={4} p="1rem" boxShadow="md">
                  <FormControl>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none">
                        <CFaUserAlt color={"black"} />
                      </InputLeftElement>
                      <Input
                        borderRadius="3xl"
                        placeholder="email address"
                        bg={"white"}
                        textColor={"black"}
                        onChange={handleChange}
                        name="email"
                        id="email"
                      />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" color="gray.300">
                        <CFaLock color={"black"} />
                      </InputLeftElement>
                      <Input
                        borderRadius="3xl"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        onChange={handleChange}
                        name="password"
                        id="password"
                        bg={"white"}
                        textColor={"black"}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={handleShowClick}
                          color="black"
                        >
                          {showPassword ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormHelperText textAlign="right">
                      {/* <Link>forgot password?</Link> */}
                      <ModalForgetPassword />
                    </FormHelperText>
                  </FormControl>
                  <Button
                    borderRadius="3xl"
                    type="submit"
                    variant="solid"
                    variantColor="teal"
                    bg="#18587c"
                    width="full"
                    onClick={handleLogin}
                    isLoading={isLoggingIn}
                  >
                    <Text color={colorMode === "light" ? "white" : "white"}>
                      login
                    </Text>
                  </Button>
                  0
                </Stack>
              </form>
            </Box>
          </div>
        </Box>

        <Text color="red">{errors}</Text>
      </Stack>
    </Flex>
  );
};

export default Login;
