import React from 'react';
import {
  useState, useEffect, useCallback, useRef
} from 'react';
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Heading,
  Box,
  Stack,
  Icon,
  Center,
} from '@chakra-ui/react';
// import { useModalContext } from 'context/modalContext';
import { Formik, Form, Field } from 'formik';
import { FiGrid } from 'react-icons/fi';
import { getCategoriesWithSubcategories } from 'api/categories';
import { useMapContext } from 'context/mapContext';



function ModalFilterMap() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { state, dispatch } = useMapContext();
  const [ isLoading, setIsLoading] = useState(true);
  const [ categories, setMyCategories] = useState([]);
  const [ trigger, setTriggerLoading] = useState([false]);

  useEffect(() => {
    setIsLoading(true);
    try {
      getAllCategory();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, trigger);

  //   const { dispatch } = useModalContext();
  //   const resetValues = (values: any) => {
  //     values.checked = [];
  //   };

  const resetFilter = () => {
    dispatch({
      type: 'REMOVE_MAP_FILTER',
    });
    onClose();
  };
  const getAllCategory = () => {
    if (state.alertRadius) {
      getCategoriesWithSubcategories().then((res) => {
        let data = res.data.data.categories.map(category => ({
          id:category.id,
          text: category.name,
          value: category.id, 
          selected: category.name,
          subcategory: category.subcategory
        }));
        setMyCategories(data);
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  return (
    <>
      <Button leftIcon={<FiGrid />} colorScheme="white" onClick={onOpen} isFullWidth variant="outline">
        {' '}
        Types
        {' '}

      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Incidents Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
                key="form"
              initialValues={{
                checked: state.selectedCategories || [],
              }}
              onSubmit={(values) => {
                dispatch({
                  type: 'SET_MAP_FILTER',
                  payload: values.checked,
                });
                onClose();
              }}
            >
              { ({ values }) => (
                <Form key="sum-form">
                  {
                        categories.map((category, id) => (
                          <Box key={`${category.text}-${id}`}>
                            <Heading as="h5" size="md" my="3">
                              {category.text}
                            </Heading>
                            <Flex wrap="wrap">
                              {
                                // @ts-ignore
                                category.subcategory.map((subCategory) => (
                                  <label key={subCategory.id}>
                                    <Box

                                      borderWidth="1px"
                                      borderRadius="lg"
                                      style={{
                                        width: '150px',
                                        textAlign: 'center',
                                        // @ts-ignore
                                        borderColor: values.checked.includes(subCategory.id.toString()) ? 'blue' : '',
                                      }}
                                      cursor="pointer"
                                      mx="2"
                                      mt="2"
                                      p="3"
                                    >
                                      {/* <img src={urls} alt="" /> */}
                                      {/* <Image src={urls} height={75} width={75} /> */}
                                      <Center><img src={subCategory.image} height={75} width={75} alt={subCategory.name} viewBox="0 0 50 50" align="center"/></Center>
                                      
                                      <p style={{ fontSize: '12px' }}>
                                        {subCategory.name}
                                      </p>
                                    </Box>
                                    <Field
                                      type="checkbox"
                                      name="checked"
                                      value={subCategory.id}
                                      style={{ display: 'none' }}
                                    />
                                  </label>
                                ))
                                }

                            </Flex>

                          </Box>
                        ))
                    }
                  <Stack spacing={2} direction="row" my="5">
                    {/* @ts-ignore */}
                    <Button onClick={resetFilter} ml="auto">
                      Reset
                    </Button>
                    <Button
                      colorScheme="teal"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalFilterMap;
