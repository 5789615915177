import React from 'react';

import {
  Box, CloseButton, Flex, Badge, Text, Icon, IconButton, Spacer, Tooltip
} from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/color-mode';
import {
  AiOutlineWhatsApp
} from 'react-icons/ai';
import {
  FiAlertCircle,
  FiMenu,
  FiLink,
} from 'react-icons/fi';
import { useUserContext } from 'context/userContext';
import { useState } from 'react';
import { assetColors } from 'data/icons';
import { generateDate } from 'utils/convertDate';
import { FiClock } from 'react-icons/fi'
import { motion } from "framer-motion";


const whatsappLink = (incident, email) => {
  const incidentName = incident.nameIncident.replace(" ", "%20")
  const text = `Hi,%20${email}%20would%20like%20to%20ask%20about%20${incidentName}`
  return `https://wa.me/6281283224729?text=${text}`
}

function IncidentBoxMap(props) {
  const { state } = useUserContext()
  const { incident, resetCallBack: resetDetails, disableDragMap, enableDragMap, viewAllDetails } = props;
  const { colorMode } = useColorMode();
  const [info, setInfo] = useState(false)

  const toggle = () => {
    setInfo(!info)
  }



  return (
    <Box
      as={motion.div}
      // onClick={() => { drag() }}
      bg={colorMode === 'light' ? 'white' : '#171923'}
      textAlign="start"
      borderRadius="lg"
      boxShadow="lg"
      drag
      dragMomentum={false}
      onMouseDown={() => { disableDragMap() }}
      onMouseUp={()=> {enableDragMap()}}
      lat={incident.latitude}
      lng={incident.longitude}
      w="md"
      style={{
        maxWidth: '280px',
        position: 'absolute',
        bottom: '250%',
        cursor: 'default',
        left: '-140px',
        // transform: 'translateX(-50%)',
        marginBottom: '25px',
        marginLeft: '-50%',
        zIndex: 9999,
      }}
      py={2}
      px={4}
    // _after={{
    //   content: '""',
    //   position: 'absolute',
    //   top: '100%',
    //   left: '50%',
    //   marginLeft: '-5px',
    //   borderWidth: '5px',
    //   borderStyle: 'solid',
    //   borderColor: `${colorMode === 'light' ? 'white' : '#171923'} transparent transparent transparent`,
    // }}
    >
      <Flex alignItems="end" flexWrap="wrap">
        {/* <Badge borderRadius="full" px="2" colorScheme="red">
          {incident.categoryIncident}
        </Badge> */}
        {/* @ts-ignore */}
        <Box bg={assetColors[incident.subCategoryIncident]} px="2" py="1" mt="1" borderRadius="md">
          <Text variant="solid" fontSize="12" fontWeight="bold" color='white'>
            {incident.subCategoryIncident}
          </Text>
        </Box>

        <CloseButton ml="auto" size="sm" onClick={() => {
          disableDragMap();
          resetDetails(incident)
          enableDragMap();
        }} />
      </Flex >
      <Text fontWeight="semibold" fontSize="16" mt="3" >
        {incident.nameIncident}
        {' '}
      </Text>
      <Text fontWeight="" fontSize="12" mt="3" align='justify' maxH="75px" noOfLines={5}>
        {incident.descriptionIncident}
      </Text>

      <table>
        <tbody>

          {
            info && (
              <>
                <tr>
                  {/* <td style={{ verticalAlign: 'top' }}>
                    <Text mt={3} fontSize="12">
                      Description:
                    </Text>
                  </td>
                  <td style={{ verticalAlign: 'top' }}>
                    <Text mt={3} fontSize="12" wordBreak={'break-all'} alignItems="justify">
                      {incident.descriptionIncident}
                    </Text>
                  </td> */}
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <Text mt={1} fontSize="12">
                      Distance to nearest asset:
                    </Text>
                  </td>
                  <td style={{ verticalAlign: 'top' }}>
                    <Text mt={1} color="red" fontSize="12">
                      {incident.closestAsset?.distanceFromAsset.toFixed(2)}
                      km (
                      {incident.closestAsset?.assetName}
                      )
                    </Text>
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <Text mt={1} fontSize="12">
                      Latitude:
                    </Text>
                  </td>
                  <td style={{ verticalAlign: 'top' }}>
                    <Text mt={1} fontSize="12">
                      {incident.latitude}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: 'top' }}>
                    <Text mt={1} fontSize="12">
                      Longitude:
                    </Text>
                  </td>
                  <td style={{ verticalAlign: 'top' }}>
                    <Text mt={1} fontSize="12">
                      {incident.longitude}
                    </Text>
                  </td>
                </tr>
              </>
            )
          }
          {/* <tr>
            <td style={{ verticalAlign: 'top' }}>
              <Text mt={1} fontSize="12">
                Reported:
              </Text>
            </td>
            <td style={{ verticalAlign: 'top' }}>
              <Text mt={1} fontSize="12" color="teal">
                {generateDate(incident.createdAt)}
              </Text>
            </td>
          </tr> */}

        </tbody>
      </table>
      <Flex alignItems="center" mt={2}>
        <Icon as={FiClock} w={4} h={4} mr="2" color="#186689" />
        <Text color="#186689" fontSize="12">
          {incident.reportedDate &&
            generateDate(incident.reportedDate)}
          {' - '}
          {
            incident.endedDate ? generateDate(incident.endedDate) : 'Unknown'
          }
        </Text>
      </Flex>
      <Flex justifyContent="center" px="2" mt="2">
        {/* <a href={whatsappLink(incident, state.user?.email || '')} onClick={ComingSoon()} target="_blank" rel="noreferrer"> */}
        <a href="#" onClick={()=>{ alert("This feature will be available in the Asset Monitoring Website 2.0"); }} rel="noreferrer">
          <Tooltip hasArrow label='Whatsapp' placement="top">
            <IconButton aria-label="alert" icon={<AiOutlineWhatsApp />} variant="ghost" fontSize='20px' />
          </Tooltip>
        </a>
        <Spacer />
        <Tooltip hasArrow label={info ? 'Less Info' : 'More Info'} placement="top">
          <IconButton aria-label="alert" icon={<FiAlertCircle />} variant="ghost" fontSize='20px' onClick={toggle} />
        </Tooltip>
        <Spacer />
        <a href={incident.originalSourceIncident} target="_blank" rel="noreferrer">
          <Tooltip hasArrow label='Original Source' placement="top">
            <IconButton aria-label="alert" icon={<FiLink />} variant="ghost" fontSize='20px' />
          </Tooltip>
        </a>
        <Spacer />
        <Tooltip hasArrow label='View Details' placement="top">
          <IconButton aria-label="alert" icon={<FiMenu />} variant="ghost" fontSize='20px' onClick={() => { viewAllDetails(incident);}} />
        </Tooltip>
        {/* <IconButton aria-label="alert" icon={<FiMenu />} variant="ghost" fontSize='25px' /> */}
      </Flex>
    </Box >
  );
}

export default IncidentBoxMap;


// -20.00030227104142
// 110.20999491122188

// -20
// 110.21