import React from 'react'
import styles from 'styles/Map.module.css';
import {
    Icon,
} from '@chakra-ui/react';
// import { ReactComponent as MyAsset } from 'public/icon_asset2.svg';
// import { ReactComponent as SuperindoAsset } from 'public/superindoasset.svg';
// import { ReactComponent as PnGAsset } from 'public/Asset_Icon_PnG.png'
import AssetBoxMap from 'components/inboard/incident/AssetBoxMap';

import { assetIcons } from 'data/icons';

// olantunji@superindo.com
function AssetMarker(props) {
    const {
        asset,
        detailAsset,
        viewDetailAsset,
        resetDetails,
        zoomAsset,
        disableDragMap,
        enableDragMap
    } = props

    let iconName = ""

    if (asset.client.email.toLowerCase().includes("danone")) {
        iconName = "Danone"
    } else if (asset.client.email.toLowerCase().includes("superindo")) {
        iconName = "Superindo"
    } else if (asset.client.email.toLowerCase().includes("michelin")) {
        iconName = "Michelin"
    } else {
        iconName = "PnG"
    }
    return (
        <div
            className={styles['marker-container']}
            key={asset.id}
            // @ts-ignore
            lat={Number(asset.latitude)}
            lng={Number(asset.longitude)}
            style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                zIndex: detailAsset.id === asset.id ? 1000 : 0,

            }}
        >
            <Icon
                key={asset.id}

                as={assetIcons[iconName]}
                boxShadow="sm"
                w={7}
                h={7}
                style={{ cursor: 'pointer' }}
                color="red"
                onClick={() => { zoomAsset(asset.latitude, asset.longitude); viewDetailAsset(asset); }}
                onContextMenu={() => { viewDetailAsset(asset); }}
            />
            {
                detailAsset.id === asset.id
                && <AssetBoxMap asset={detailAsset} resetCallBack={resetDetails} disableDragMap={disableDragMap} enableDragMap={enableDragMap} />
            }

        </div>
    )
}
export default AssetMarker
