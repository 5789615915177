import React from 'react';
import {
  Flex, Heading, Box, Button, Text, Icon
} from '@chakra-ui/react';
import { assetIcons } from 'data/icons';
import { FiClock } from 'react-icons/fi'
import { generateDate } from 'utils/convertDate'
import {SubCategoryIcon, setIcon} from 'assets/SubCategoryIcon';


// const generateDate = (date: Date | undefined) => {
//   const optionsDate: any = { month: 'short', day: 'numeric', hourCycle: 'h12' };
//   const optionsTime: any = { hour: '2-digit', minute: '2-digit' }
//   const newDate = new Date(date || '')
//   const finalDate = newDate.toLocaleDateString('en-GB', optionsDate)
//   const finalTime = newDate.toLocaleTimeString('en-US', optionsTime)
//   return `${finalDate} ${finalTime}`
// }

function IncidentCard({
  incident,
  editCallBack,
  deleteCallBack,
  addCallBack,
}) {
  setIcon(incident.subcategories.image);
  return (
    <Box w="sm" borderWidth="1px" borderRadius="lg" p={6} mr={6} mb={6}>
      <Flex alignItems="center">
        <Box flex="1">
          <Heading as="h5" size="sm">
            {incident.nameIncident}
          </Heading>
          <Text mt={2} noOfLines={3}>
            {incident.descriptionIncident}
          </Text>
          <Flex alignItems="center" mt={2}>
            <Icon as={FiClock} w={4} h={4} mr="2" color="#186689" />
            <Text color="#186689" fontSize="12">
              {incident.reportedDate &&
                generateDate(incident.reportedDate)}
              {' - '}
              {
                incident.endedDate ? generateDate(incident.endedDate) : 'Unknown'
              }
            </Text>

          </Flex>
          <Flex mt={2}>
            <Button size='sm' bg="#059976" onClick={() => { editCallBack(incident); }}>
              <Text color='white'>Edit</Text>
            </Button>
            <Button size='sm' ml={1} bg="#e53030" onClick={() => { deleteCallBack(incident); }}>
              <Text color='white'>Delete</Text>
            </Button>
            <Button size='sm' ml={1} bg="#186689" onClick={() => { addCallBack(incident); }}>
              <Text color='white'>Add casualty</Text>
            </Button>
          </Flex>
        </Box>
      <Flex ml={3}>

        <Icon
          w={'auto'}
          h={'auto'}
          // @ts-ignore
          as={SubCategoryIcon}
        />
      </Flex>
          

        
      </Flex>


    </Box>
  );
}

export default IncidentCard;
