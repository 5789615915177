/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';

import {
  Container, Box,
} from '@chakra-ui/react';
import IncidentForm from 'components/inboard/incident/IncidentForm';
import InboardLayout from 'layout/InboardLayout';
import SEO from 'components/SEO'

function AddIncident() {
  return (
    <Container maxW="container.lg" mt={6}>
        <SEO title="Add Incidents - Asset Monitoring" />
      <Box borderWidth="1px" borderRadius="lg" px="8" pb="6">
        <IncidentForm />
      </Box>
    </Container>
  );
}

AddIncident.layout = function getLayout(page) {
  return (
    <InboardLayout>
      {page}
    </InboardLayout>
  );
};

export default AddIncident;
