import * as React from "react"

var icon = "" // with require
export const setIcon = (base64) =>{
    icon = base64
}

export const SubCategoryIcon = (props) => (
    <img src={icon} viewBox="0 0 50 50" {...props}></img>
)
