import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,

  Button,
  Box,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import TextField from 'components/inboard/base/TextField';
import { useModalContext } from 'context/modalContext';
import { downloadIncidents } from 'api/incidents';

const formAttributes = [
  {
    name: 'year',
    id: 'year',
    labelName: 'Year',
    placeholder: 'Please input year',
    type: 'text',
  },
];

const validate = Yup.object().shape({
  year: Yup.number().required('Required').typeError('Value must be a number'),
});

const currentYear = new Date().getFullYear();

const mapFormAttributes = () => formAttributes.map((attribute) => (
  <Field name={attribute.name} key={attribute.id}>
    {({ field, form }) => (
      <TextField
        key={attribute.id}
        id={attribute.name}
        form={form}
        field={field}
        labelName={attribute.labelName}
        placeholder={attribute.placeholder}
        // @ts-ignore
        type={attribute.type}
      />
    )}
  </Field>
));

function ModalDowloadIncident() {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch } = useModalContext();

  return (
    <>
      <Button colorScheme="teal" onClick={() => { setIsOpen(true); }} mr="3">
        {' '}
        Download Incidents
      </Button>

      <Modal isOpen={isOpen} onClose={() => { }} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Incident</ModalHeader>
          <ModalBody pb={8}>
            <Formik
              initialValues={{
                year: currentYear,
              }}
              validationSchema={validate}
              // eslint-disable-next-line no-unused-vars
              onSubmit={(values, actions) => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    show: true,
                    status: 'LOADING',
                    message: 'Trying to get your data...',
                    title: 'Loading...',
                  },
                });
                const yearData = {
                  year: values.year,
                };
                downloadIncidents(yearData).then((res) => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `Incidents-${yearData.year}.xlsx`);
                  document.body.appendChild(link);
                  link.click();
                  dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      show: true,
                      status: 'SUCCESS',
                      message: 'Successfully downloaded',
                      title: 'Success!',
                    },
                  });
                  setIsOpen(false);
                }).catch((err) => {
                  console.log(err);
                  dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      show: true,
                      status: 'FAILED',
                      message: 'Something went wrong',
                      title: 'Failed!',
                    },
                  });
                });
              }}
            >
              {(formik) => (
                <Form>
                  {mapFormAttributes()}
                  <Box flexDirection="row" mt={8}>
                    <Button
                      colorScheme="teal"
                      onClick={() => { setIsOpen(false); }}
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="teal"
                      ml={3}
                      type="submit"
                      disabled={!formik.isValid}
                    >
                      Submit
                    </Button>

                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalDowloadIncident;
