import React from 'react'
import styles from 'styles/Map.module.css';
import {
    Icon,
} from '@chakra-ui/react';
import {SubCategoryIcon, setIcon} from 'assets/SubCategoryIcon';
// import Aoc from 'assets/Aoc';
// import Road from 'public/icon_Axes of Communication_Road.svg';


const IncidentMarker = (props) => {
    const {
        incident,
        detailIncident,
        viewPopup,
        resetDetails,
        zoomAsset
    } = props
    setIcon(incident.subcategories.image);
    return (
        <div
            className={styles['marker-container']}
            style={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // zIndex: detailIncident.id === incident.id ? 1000 : 0,
            }}
        >
            <Icon
                as={SubCategoryIcon}
                key={incident.id}
                boxShadow="sm"
                w={6}
                h={6}
                color="red"
                style={{ cursor: 'pointer' }}
                onClick={() => { zoomAsset(incident.latitude, incident.longitude); viewPopup(incident); }}
                onContextMenu={() => { viewPopup(incident); }}
            />
            {/* {
                detailIncident.id === incident.id
                && <IncidentBoxMap incident={detailIncident} resetCallBack={resetDetails} />
            } */}
        </div>
    )
}

export default IncidentMarker
