import React from 'react';

import {
  createContext,
  useContext,
  useReducer,
} from 'react';

import reducer from './userReducer';

// Initial State
const initialState = {
  user: {
    uid: '',
    role: 'admin',
    email: '',
  },
  categories: [],
};

const userContext = createContext({
  state: initialState,
  dispatch: () => {},
});

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <userContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export const useUserContext = () => {
  const { state, dispatch } = useContext(userContext);
  return { state, dispatch };
};
