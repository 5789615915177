import { ReactElement, useState, useEffect } from 'react';
import React from 'react';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Button,
  Td,
  Heading,
  Box,
  Flex,
  IconButton
} from '@chakra-ui/react';
import XLSX from 'xlsx';
import { useModalContext } from 'context/modalContext';
import InboardLayout from 'layout/InboardLayout';
import ModalCreateUser from 'components/inboard/user/ModalCreateUser';
import { createBatchAssets } from 'api/assets';
import { getUser } from 'api/auth';
import ModalDownloadIncident from 'components/inboard/user/ModalDownloadIncident';
import ModalDeleteUser from 'components/inboard/user/ModalDeleteUser';
import SEO from 'components/SEO'

function ListUsers() {
  // eslint-disable-next-line no-unused-vars
  const [selectedUid, setSelectedUid] = useState('');
  const [users, setUsers] = useState([]);
  const { dispatch } = useModalContext();

  const getUsers = () => {
    getUser().then((res) => {
      const { users: allUsers } = res.data.data;
      const someUsers = allUsers.filter((user) => user.role !== 'super_admin');
      setUsers(someUsers);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const chooseFile = (event, uid) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
    const doc = document.querySelector(`#import-${uid}`);
    doc.value = '';
    setSelectedUid(uid);
    doc.click();
  };

  const deleteEmptyRow = (excelArray) => {
    const indexes = [];
    excelArray.forEach((row, index) => {
      const numberOfKeys = Object.keys(row).length;
      let numberOfEmptyCells = 0;
      Object.keys(row).forEach((key) => {
        const cell = row[key].trim();
        if (cell === '') {
          // eslint-disable-next-line no-plusplus
          numberOfEmptyCells++;
        }
      });
      if (numberOfEmptyCells === numberOfKeys) {
        indexes.push(index);
      }
    });
    const newExcelArray = excelArray.filter((row, index) => !indexes.includes(index));
    return newExcelArray;
  };

  const assetHeader = {
    PROVINCE: 'Province',
    REGION: 'City/Region',
    ASSET_NAME: 'Asset Name',
    ADDRESS: 'Address',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
  };
  const validationAsset = (data) => {
    const errors = [];
    const dataFile = [];
    let errorHeader = false;
    const excelObject = deleteEmptyRow(data);
    excelObject.forEach((row, index) => {
      // Check Headers
      if (index === 0) {
        if (String(Object.values(assetHeader).sort()) !== String(Object.keys(row).sort())) {
          errors.push({
            line: 'header',
            message: 'format XLS tidak sesuai',
          });
          errorHeader = true;
        }
      }
      console.log(errors);
      // Check the value of each cell
      if (!errorHeader) {
        Object.keys(row).forEach((header) => {
          const cellValue = row[header];
          if (cellValue === '') {
            errors.push({
              line: `${index + 2} ${header}`,
              message: 'cell tidak boleh kosong',
            });
          }
        });
      }
      // convert xls data of each row to JSON object
      if (errors.length === 0) {
        dataFile.push({
          clientId: selectedUid,
          assetName: row[assetHeader.ASSET_NAME],
          longitude: row[assetHeader.LONGITUDE],
          latitude: row[assetHeader.LATITUDE],
          region: row[assetHeader.REGION],
          province: row[assetHeader.PROVINCE],
          address: row[assetHeader.ADDRESS],
        });
      }
    });
    return { errors, dataFile };
  };

  const readerOnLoad = async (e) => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        show: true,
        status: 'LOADING',
        message: 'Trying to get your data...',
        title: 'Loading...',
      },
    });
    let dataValidation;
    // logic to convert excel to json object
    const wb = XLSX.read(e.target.result, { type: 'array', cellDates: true, cellText: false });
    const excelObject = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { raw: false, defval: '', dateNF: 'DD/MM/YYYY' });
    // check validation
    if (excelObject.length === 0) {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          show: true,
          status: 'FAILED',
          message: 'File cannot be empty',
          title: 'FAILED!',
        },
      });
    } else {
      dataValidation = validationAsset(excelObject);
      if (dataValidation.errors.length > 0) {
        // TODO: Create errors
        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'FAILED',
            message: JSON.stringify(dataValidation.errors),
            title: 'FAILED!',
          },
        });
        return;
      }
      const jsonObject = {
        data: [...dataValidation.dataFile],
      };
      createBatchAssets(selectedUid, jsonObject).then((res) => {
        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'SUCCESS',
            message: 'Success',
            title: 'Successfully Updated User\'s Assets',
          },
        });
      }).catch((err) => {
        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'FAILED',
            message: err.response.data.message,
            title: 'FAILED!',
          },
        });
      });
    }
  };

  const handleFileUploaded = (file) => {
    const fileType = file.name
      .split('.')
      .slice(-1)
      .join('.');

    if (fileType === 'csv' || fileType === 'xlsx') {
      const reader = new FileReader();
      reader.onload = (e) => readerOnLoad(e);
      reader.readAsArrayBuffer(file);
    } else {
      dispatch({
        type: 'SHOW_MODAL',
        payload: {
          show: true,
          status: 'FAILED',
          message: 'File is not .xslx or .csv',
          title: 'Failed!',
        },
      });
    }
  };

  const onChangeImport = (event) => {
    handleFileUploaded(event.target.files[0]);
  };

  return (
    <div>
      <SEO title="List Users - Asset Monitoring" />
      <Flex>
        <Heading as="h3">
          List Users
        </Heading>
        <a href="/template_excel.xlsx" download="Excel Template" style={{ marginLeft: 'auto' }}>
          <Button ml="auto" mr="3">
            Download Template Excel
          </Button>
        </a>
        <ModalDownloadIncident />
        <ModalCreateUser callback={getUsers} />
      </Flex>
      <Box borderWidth="1px" borderRadius="lg" mt="8">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              users.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.email}</Td>
                  <Td>{user.role}</Td>
                  {
                    user.role === 'user' &&
                    < Td >
                      <Button onClick={(e) => { chooseFile(e, user.id); }}>
                        Import Excel (Assets)
                      </Button>

                      <input
                        type="file"
                        // eslint-disable-next-line react/no-string-refs
                        id={`import-${user.id}`}
                        style={{ display: 'none' }}
                        accept=".xlsx"
                        onChange={onChangeImport}
                      />
                      <ModalDeleteUser email={user.email} uid={user.id} refreshUsers={getUsers}></ModalDeleteUser>
                    </Td>
                  }
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </Box>
    </div >
  );
}

ListUsers.layout = function getLayout(page) {
  return (
    <InboardLayout>
      {page}
    </InboardLayout>
  );
};
export default ListUsers;
