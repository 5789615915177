/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import Cookies from 'js-cookie';

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

export const axiosFile = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
  responseType: 'blob',
});

const axiosFallback = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

const axiosFileFallback = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
  responseType: 'blob',
});

axiosClient.interceptors.request.use(
  (request) => {
    const token = Cookies.get('user');
    // const token = ''
    const auth = token ? `Bearer ${token}` : '';
    if (request && request.headers) {
      request.headers.Authorization = auth;
    }
    return request;
  },
);

axiosClient.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line consistent-return
  (error) => new Promise((resolve, reject) => {
    const originalReq = error.config;
    if (error.response && error.response.status !== null) {
      const statusCode = error.response.status;
      if (statusCode === 401 || statusCode === 403) {
        originalReq._retry = true;
        let response
        axiosFallback.get('/refresh-token').then((res) => {
          response = res;
          console.log(response)
          if (response) {
            Cookies.set('user', response.data.data.token);
            const auth = response.data.data.token ? `Bearer ${response.data.data.token}` : '';
            if (originalReq && originalReq.headers) {
              originalReq.headers.Authorization = auth;
            }
          }
          return axios(originalReq);
        });
        return resolve(response);
      }
      if (statusCode === 400) {
        return reject(error);
      }
      if (statusCode === 404) {
        return reject(error);
      }
      if (statusCode === 500) {
        return reject(error);
      }
    }
  }),
);

axiosFile.interceptors.request.use(
  (request) => {
    const token = Cookies.get('user');
    // const token = ''
    const auth = token ? `Bearer ${token}` : '';
    if (request && request.headers) {
      request.headers.Authorization = auth;
    }
    return request;
  },
);

axiosFile.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line consistent-return
  (error) => new Promise((resolve, reject) => {
    const originalReq = error.config;
    if (error.response && error.response.status !== null) {
      const statusCode = error.response.status;
      if (statusCode === 401 || statusCode === 403) {
        originalReq._retry = true;
        let response;
        axiosFileFallback.get('/refresh-token').then((res) => {
          response = res;
          if (response) {
            console.log(response)
            Cookies.set('user', response.data.data.token);
            const auth = response.data.data.token ? `Bearer ${response.data.data.token}` : '';
            if (originalReq && originalReq.headers) {
              originalReq.headers.Authorization = auth;
            }
          }
          return axios(originalReq);
        });
        return resolve(response);
      }
      if (statusCode === 400) {
        return reject(error);
      }
      if (statusCode === 404) {
        return reject(error);
      }
      if (statusCode === 500) {
        return reject(error);
      }
    }
  }),
);

export default axiosClient;
