import React from 'react'
import { Button, IconButton } from '@chakra-ui/button'
import { useColorMode } from '@chakra-ui/color-mode';
import { FiSun, FiMoon } from 'react-icons/fi';

function Toggle() {
    const { colorMode, toggleColorMode } = useColorMode();

    return (
        <IconButton aria-label="toggle" icon={colorMode === 'light' ? <FiMoon /> : <FiSun />} onClick={toggleColorMode} mx="3">
            {colorMode} mode
        </IconButton>
    )
}

export default Toggle
