// theme.ts
import React from 'react';

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'

// 2. Add your color mode config
const config= {
    initialColorMode: 'dark',
}

// 3. extend the theme
export const theme = extendTheme({ config })

// export default theme