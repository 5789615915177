import React from 'react';
import { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  IconButton,
  Divider,
  Avatar,
  Heading,
  Icon
} from '@chakra-ui/react';
import {
  FiMenu,
  FiMapPin,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { AiOutlineWarning, AiFillWarning, AiOutlineLogout, AiOutlineUnorderedList } from 'react-icons/ai';
import { useLocation, Link } from 'react-router-dom'
import NavItem from 'components/inboard/sidebar/NavItem';
import { useUserContext } from 'context/userContext';
import Toggle from './Toggle';
import Logo from 'assets/Logo';
import { assetIcons } from 'data/icons';

export default function Sidebar() {
  const { state } = useUserContext();
  const [navSize, changeNavSize] = useState('large');
  const router = useLocation();
  const isActive = (route) => {
    return route === router.pathname.toString();
  };
  return (
    <Flex
      top="-1px"
      pos="sticky"
      left="5"
      h="95vh"
      marginTop="2.5vh"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      borderRadius={navSize === 'small' ? '15px' : '30px'}
      w={navSize === 'small' ? '75px' : '200px'}
      flexDir="column"
      justifyContent="space-between"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
        as="nav"
      >
        <Flex
          flexDir={navSize === 'small' ? 'column' : 'row'}
          alignItems={navSize === 'small' ? 'center' : 'flex-start'}
          mt={5}
        >
          <Icon
            as={assetIcons["Logo"]}
            boxShadow="sm"
            w={navSize === 'small' ? '100%' : '75%'}
            h="auto"
            col5r="red"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (navSize === 'small') { changeNavSize('large'); } else { changeNavSize('small'); }
            }}
          />
          <IconButton
            background="none"
            aria-label=""
            mt={navSize === 'small' ? 0 : 5}
            _hover={{ background: 'none' }}
            icon={<FiMenu />}
            onClick={() => {
              if (navSize === 'small') { changeNavSize('large'); } else { changeNavSize('small'); }
            }}
          />
        </Flex>
        <NavItem navSize={navSize} icon={FiMapPin} title="Map" link="/dashboard/map" active={isActive('/dashboard/map')} />
        {/* my incidents (admin only) */}
        {
          state.user?.role === 'admin'
          && (
            <>
              <NavItem navSize={navSize} icon={AiOutlineWarning} title="Add Incidents" link="/dashboard/admin/add-incident" active={isActive('/dashboard/admin/add-incident')} />
              <NavItem navSize={navSize} icon={AiFillWarning} title="My Incidents" link="/dashboard/admin/my-incident" active={isActive('/dashboard/admin/my-incident')} />
              <NavItem navSize={navSize} icon={AiOutlineUnorderedList} title="Categories" link="/dashboard/admin/add-subcategory" active={isActive('/dashboard/admin/add-subcategory')} />
            </>
          )
        }
        {
          state.user?.role === 'super_admin'
          && (
            <>
              <NavItem navSize={navSize} icon={FiUsers} title="List Users" link="/dashboard/super-admin/list-users" active={isActive('/dashboard/super-admin/list-users')} />
            </>
          )
        }
        {/* write incidents (admin only) */}
        <Divider mt="30" />
        <NavItem navSize={navSize} icon={FiUser} title="Profile" link="/dashboard/profile" active={isActive('/dashboard/profile')} />
        <NavItem navSize={navSize} icon={AiOutlineLogout} title="Logout" link="/dashboard/logout" />
      </Flex>
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
        cursor="pointer"
      >
        <Divider display={navSize === 'small' ? 'none' : 'flex'} />
        <Flex flexDir="column" width="100%" alignItems={navSize === 'small' ? 'center' : 'flex-start'} >
          <Link to="/dashboard/profile" onClick={() => {console.log("clicked")}}>
            <Flex my={4} align="center">
              <Avatar size="sm" />
              <Flex flexDir="column" ml={4} display={navSize === 'small' ? 'none' : 'flex'} style={{ width: '100%' }}>
                <Heading as="h3" size="sm" fontSize="13">{state.user?.email || 'not defined'}</Heading>
                <Text color="gray" fontSize="12">{state.user?.role || 'not defined'}</Text>
              </Flex>
            </Flex>
          </Link>
        </Flex> 
        <Flex flexDir="column" width="100%">
           <Toggle />
        </Flex>
       
      </Flex>
    </Flex >
  );
}
