import React from 'react';
import { useEffect } from 'react';
import {
  Flex,
  Text,
  Divider,
  Box,
  Button,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiMap, FiMove } from 'react-icons/fi';
import { useMapContext } from 'context/mapContext';
import { mapStyles, normalMap } from 'styles/mapStyles';
import ModalFilterMap from '../../incident/ModalFilterMap';
import ModalFilterDate from '../../incident/ModalFilterDate';

export default function Sidebar() {
  const { dispatch } = useMapContext();

  const router = useHistory();

  const setMapOptions = (options) => {
    dispatch({
      type: 'SET_MAP_OPTIONS',
      payload: options,
    });
  };

  return (
    <Flex
      pos="sticky"
      left="5"
      h="95vh"
      marginTop="2.5vh"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      borderRadius="30px"
      w="180px"
      flexDir="column"
      justifyContent="space-between"
    >
      <Flex
        p="5%"
        flexDir="column"
        w="100%"
        alignItems="flex-start"
        as="nav"
      >
        <Text>
          Map Views:
        </Text>
        <Button leftIcon={<FiMap />} isFullWidth mt="5" onClick={() => setMapOptions('roadmap')}>Normal Mode</Button>
        <Button leftIcon={<FiMove />} isFullWidth mt="2" onClick={() => setMapOptions('satellite')}>Satelite Mode</Button>
        <Divider my="4" />
        <Text mb="5">
          Filter Incidents by:
        </Text>
        <ModalFilterMap />
        <ModalFilterDate />
        <Box borderWidth="1px" isFullWidth borderColor="rgba(255,255,255,0.5)" borderRadius="lg" mt={5} bg="rgba(255,255,255,0.03)">
          <Text align='center' color='white'>
            The events appeared occurred within 48h
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
