import React from 'react';
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  ButtonGroup,
  ModalFooter,

} from '@chakra-ui/react';
import { FiCalendar } from 'react-icons/fi';
// import { useModalContext } from 'context/modalContext';

import 'react-datepicker/dist/react-datepicker.css';
import { useMapContext } from 'context/mapContext';

function ModalFilterDate() {
  const date = new Date();
  date.setDate(date.getDate() - 2);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { state, dispatch } = useMapContext();

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const setDates = () => {
    dispatch(
      {
        type: 'SET_DATE_FILTER',
        payload: {
          start: startDate,
          end: endDate,
        },
      },
    );
    onClose();
  };

  const resetDates = () => {
    setStartDate(date);
    setEndDate(new Date());
    dispatch({
      type: 'REMOVE_DATE_FILTER',
      payload: {},
    });
    onClose();
  };

  return (
    <>
      <Button leftIcon={<FiCalendar />} colorScheme="white" onClick={onOpen} isFullWidth mt="3" variant="outline">
        {'    '}
         Date
        {' '}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Incidents Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center" pb={6}>
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button onClick={resetDates}>
                Reset
              </Button>
              <Button onClick={setDates}>
                Save
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalFilterDate;
