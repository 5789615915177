import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,

  Button,
  Box,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import TextField from 'components/inboard/base/TextField';
import SelectField from 'components/inboard/base/SelectField';
import { useModalContext } from 'context/modalContext';
import { createUser } from 'api/auth';

const formAttributes = [
  {
    name: 'email',
    id: 'email',
    labelName: 'Email',
    placeholder: 'Please input email',
    type: 'text',
  },
  {
    name: 'password',
    id: 'password',
    labelName: 'Password',
    placeholder: 'Please input password',
    type: 'password',
  },
  {
    name: 'confirmPassword',
    id: 'confirmPassword',
    labelName: 'Confirm Password',
    placeholder: 'Please confirm your password',
    type: 'password',
  },
  {
    name: 'role',
    id: 'role',
    labelName: 'Role',
    placeholder: 'Please input role',
    type: 'select',
  },
];

const validate = Yup.object().shape({
  email: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'must match with password'),
});

const mapFormAttributes = () => formAttributes.map((attribute) => (
  <Field name={attribute.name} key={attribute.id}>
    {({ field, form }) => (
      attribute.type === 'select' ? (
        <SelectField
          key={attribute.id}
          id={attribute.name}
          form={form}
          field={field}
          labelName={attribute.labelName}
          placeholder={attribute.placeholder}
          options={[
            {
              id: 0,
              text: 'User',
              value: 'user',
            },
            {
              id: 1,
              text: 'Admin',
              value: 'admin',
            },
          ]}
        />
      ) : (
        <TextField
          key={attribute.id}
          id={attribute.name}
          form={form}
          field={field}
          labelName={attribute.labelName}
          placeholder={attribute.placeholder}
          // @ts-ignore
          type={attribute.type}
        />
      )
    )}
  </Field>
));

function ModalCreateUser(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { callback } = props;

  const { dispatch } = useModalContext();

  return (
    <>
      <Button onClick={() => { setIsOpen(true); }}>
        {' '}
        Create User
      </Button>

      <Modal isOpen={isOpen} onClose={() => { }} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create User</ModalHeader>
          <ModalBody pb={8}>
            <Formik
              initialValues={{
                email: '',
                password: '',
                confirmPassword: '',
                role: '',
              }}
              validationSchema={validate}
              // eslint-disable-next-line no-unused-vars
              onSubmit={(values, actions) => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    show: true,
                    status: 'LOADING',
                    message: 'Trying to get your data...',
                    title: 'Loading...',
                  },
                });
                const data = {
                  email: values.email,
                  password: values.password,
                  role: values.role,
                };
                createUser(data).then((res) => {
                  dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      show: true,
                      status: 'SUCCESS',
                      message: 'Successfully created User',
                      title: 'Successfully created User',
                    },
                  });
                  callback();
                  setIsOpen(false);
                }).catch((err) => {
                  dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      show: true,
                      status: 'FAILED',
                      message: 'Something went wrong',
                      title: 'Something went wrong',
                    },
                  });
                  setIsOpen(false);
                  console.log(err);
                });
              }}
            >
              {(formik) => (
                <Form>
                  {mapFormAttributes()}
                  <Box flexDirection="row" mt={8}>
                    <Button
                      colorScheme="teal"
                      onClick={() => { setIsOpen(false); }}
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="teal"
                      ml={3}
                      type="submit"
                      disabled={!formik.isValid || !formik.dirty}
                    >
                      Submit
                    </Button>

                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalCreateUser;
