import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Divider,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spacer,
  Icon,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { FiMaximize2, FiX, FiMapPin, FiClock } from "react-icons/fi";
import { assetColors } from "data/icons";
import { generateDate } from "utils/convertDate";
import { Doughnut } from "react-chartjs-2";

function DetailWindow(props) {
  const { colorMode } = useColorMode();
  const { incident, setMapHeight, resetIncident, setZoomLevel } = props;
  const [expand, setExpand] = useState(false);
  const data = {
    labels: [
      "Civilian Injuries",
      "Civilian Death",
      "Police Injuries",
      "Police Death",
      "Military Injuries",
      "Military Death",
      "Criminal Injuries",
      "Criminal Death",
      "Emergency Service Injuries",
      "Emergency Service Deaths",
    ],
    datasets: [
      {
        data: [
          incident.civilianInjuries,
          incident.civilianDeath,
          incident.policeInjuries,
          incident.policeDeath,
          incident.militaryInjuries,
          incident.militaryDeath,
          incident.criminalInjuries,
          incident.criminalDeath,
          incident.emergencyInjuries,
          incident.emergencyDeath,
        ],
        backgroundColor: [
          "rgba(180, 0, 228, 0.2)",
          "rgba(69, 57, 186, 0.2)",
          "rgba(143, 240, 252, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(122, 0, 202, 0.2)",
          "rgba(246, 59, 49, 0.2)",
          "rgba(54, 112, 197, 0.2)",
          "rgba(253, 188, 61, 0.2)",
          "rgba(234, 147, 248, 0.2)",
          "rgba(163, 167, 248, 0.2)",
        ],
        borderColor: [
          "rgba(180, 0, 228, 1)",
          "rgba(69, 57, 186, 1)",
          "rgba(143, 240, 252, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(122, 0, 202, 1)",
          "rgba(246, 59, 49, 1)",
          "rgba(54, 112, 197, 1)",
          "rgba(253, 188, 61, 1)",
          "rgba(234, 147, 248, 1)",
          "rgba(163, 167, 248, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    // setDraggable(false)
  }, []);
  // const { incident } = props
  // const incident = {
  //     id: 'string',
  //     nameIncident: 'Telecommuncations tower torched in Europian, Nuenen',
  //     descriptionIncident: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse atque, dolore tempora officia nihil cum, nemo quasi ullam, alias ea aspernatur accusamus ab eligendi delectus accusantium nulla ducimus consequatur sint!',
  //     categoryIncident: 'crime',
  //     subCategoryIncident: 'Robbery',
  //     originalSourceIncident: 'https://something.com',
  //     longitude: 11.0,
  //     latitude: 212.00,
  //     originalLatitude: 110.0,
  //     originalLongitude: -22.0,
  //     closestAsset: {
  //         id: '12',
  //         assetName: 'Some asset',
  //         assetLongitude: 20.0,
  //         assetLatitude: 12.0,
  //         distanceFromAsset: 12221
  //     },
  //     createdAt: Date.now(),
  //     reportedAt: Date(),
  //     endedAt: Date()
  // }

  const setExpandHeight = () => {
    if (expand) {
      setMapHeight("49vh");
    } else {
      setMapHeight("0");
    }
    setExpand(!expand);
  };

  const resetAll = () => {
    resetIncident();
    setMapHeight("93vh");
    setZoomLevel(5);
    // setDraggable(true)
  };

  const options = {
    layout: {},
    plugins: {
      responsive: true,
      legend: {
        position: "bottom",
        labels: {
          padding: 20,
          color: colorMode === "light" ? "black" : "white",
        },
      },
    },
  };
  return (
    <div>
      <Box
        style={{ height: expand ? "95vh" : "45vh" }}
        bg={colorMode === "light" ? "whiteAlpha.700" : "#202c3e"}
      >
        <Flex p="2" alignItems="center">
          <Text fontSize="12">Incident Detail</Text>
          <IconButton
            aria-label="maximize"
            icon={<FiMaximize2 />}
            size="xs"
            fontSize="12px"
            ml="auto"
            variant="ghost"
            onClick={() => {
              setExpandHeight();
            }}
          />
          <IconButton
            aria-label="cancel"
            icon={<FiX />}
            size="xs"
            fontSize="16px"
            variant="ghost"
            onClick={() => {
              resetAll();
            }}
          />
        </Flex>
        <Divider />
        <Tabs>
          <TabList>
            <Tab ml="4">Overview</Tab>
          </TabList>
          <TabPanels>
            <TabPanel h="100%">
              <Grid
                templateColumns="repeat(1, 1fr)"
                gap={3}
                h={expand ? "80vh" : "30vh"}
                overflow="auto"
              >
                <Box
                  w="100%"
                  bg="gray.800"
                  p="4"
                  bg={colorMode === "light" ? "whiteAlpha.700" : "#202c3e"}
                >
                  <Text>Report</Text>
                  <Divider></Divider>
                  {/* @ts-ignore */}
                  <Box
                    bg={assetColors[incident.subCategoryIncident]}
                    px="2"
                    py="1"
                    mt="1"
                    borderRadius="md"
                  >
                    <Text
                      variant="solid"
                      fontSize="12"
                      fontWeight="bold"
                      color="white"
                    >
                      {incident.subCategoryIncident}
                    </Text>
                  </Box>
                  <Text fontSize="18" fontWeight="semibold" mt="2">
                    {incident.nameIncident}
                  </Text>
                  <Flex alignItems="center" mt="2">
                    <Icon as={FiMapPin} w={4} h={4} />
                    <Flex direction="column" ml="4">
                      <Text fontSize="12">
                        Latitude: {incident.originalLatitude}
                      </Text>
                      <Text fontSize="12">
                        Longitude: {incident.originalLongitude}
                      </Text>
                    </Flex>
                    <Icon as={FiClock} w={4} h={4} ml="6" />
                    <Flex direction="column" ml="4">
                      <Text fontSize="12">
                        Started at: {generateDate(incident?.reportedDate)}
                      </Text>
                      <Text fontSize="12">
                        Ended at: {generateDate(incident?.endDate)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Text fontSize="12" mt="2">
                    {incident.descriptionIncident}
                  </Text>
                  {incident.civilianInjuries == null ? null : (
                    <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                      <Flex ml="4" mt="16">
                        <Doughnut data={data} options={options} />
                      </Flex>
                      <Flex direction={"column"} ml="4" mt="8">
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Civilian Injuries</Text>
                          <Text fontSize="14" align="end">
                            {incident.civilianInjuries}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Civilian Deaths</Text>
                          <Text fontSize="14" align="end">
                            {incident.civilianDeath}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14"> Police Injuries</Text>
                          <Text fontSize="14" align="end">
                            {incident.policeInjuries}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Police Deaths</Text>
                          <Text fontSize="14" align="end">
                            {incident.policeDeath}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Military Injuries</Text>
                          <Text fontSize="14" align="end">
                            {incident.militaryInjuries}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Military Deaths</Text>
                          <Text fontSize="14" align="end">
                            {incident.militaryDeath}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Criminal Injuries</Text>
                          <Text fontSize="14" align="end">
                            {incident.criminalInjuries}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Criminal Deaths</Text>
                          <Text fontSize="14" align="end">
                            {incident.criminalDeath}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Emergency Services Injuries</Text>
                          <Text fontSize="14" align="end">
                            {incident.emergencyInjuries}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                        <Grid templateColumns="repeat(2, 1fr)" gap={12} mt="4">
                          <Text fontSize="14">Emergency Service Deaths</Text>
                          <Text fontSize="14" align="end">
                            {incident.emergencyDeath}
                          </Text>
                        </Grid>
                        <Divider orientation="horizontal" />
                      </Flex>
                    </Grid>
                  )}

                  <Text fontSize="12" mt="2" color="red">
                    {incident.closestAsset?.distanceFromAsset.toFixed(2)} km
                    away from {incident.closestAsset?.assetName}
                  </Text>
                </Box>
              </Grid>
            </TabPanel>
            <TabPanel h="100%">
              <Grid
                templateColumns="repeat(1, 1fr)"
                gap={3}
                h={expand ? "80vh" : "30vh"}
                overflow="auto"
              >
                <Box
                  w="100%"
                  bg="gray.800"
                  p="4"
                  bg={colorMode === "light" ? "whiteAlpha.700" : "#202c3e"}
                >
                  <Text>Report</Text>
                  <Divider></Divider>
                  {/* @ts-ignore */}
                  <Box
                    bg={assetColors[incident.subCategoryIncident]}
                    px="2"
                    py="1"
                    mt="1"
                    borderRadius="md"
                  >
                    <Text
                      variant="solid"
                      fontSize="12"
                      fontWeight="bold"
                      color="white"
                    >
                      {incident.subCategoryIncident}
                    </Text>
                  </Box>
                  <Text fontSize="18" fontWeight="semibold" mt="2">
                    {incident.nameIncident}
                  </Text>
                  <Flex alignItems="center" mt="2">
                    <Icon as={FiMapPin} w={4} h={4} />
                    <Flex direction="column" ml="4">
                      <Text fontSize="12">
                        Latitude: {incident.originalLatitude}
                      </Text>
                      <Text fontSize="12">
                        Longitude: {incident.originalLongitude}
                      </Text>
                    </Flex>
                    <Icon as={FiClock} w={4} h={4} ml="6" />
                    <Flex direction="column" ml="4">
                      <Text fontSize="12">
                        Started at: {generateDate(incident?.reportedDate)}
                      </Text>
                      <Text fontSize="12">
                        Ended at: {generateDate(incident?.endedDate)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Text fontSize="12" mt="2" align='justify'>
                    {incident.descriptionIncident}
                  </Text>

                  <Text fontSize="12" mt="2" color="red">
                    {incident.closestAsset?.distanceFromAsset.toFixed(2)} km
                    away from {incident.closestAsset?.assetName}
                  </Text>
                </Box>
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
}

export default DetailWindow;
