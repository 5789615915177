import React from 'react';
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Switch,
  Text,
  Icon,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import TextField from 'components/inboard/base/TextField';
import SelectField from 'components/inboard/base/SelectField';
import { createIncident, updateIncident } from 'api/incidents';
import { useUserContext } from 'context/userContext';
import { useModalContext } from 'context/modalContext';
import { allCategories, subCategories } from 'data/categories';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { FormLabel, Flex, Box } from '@chakra-ui/react'
import "react-datepicker/dist/react-datepicker.css";


function CasualityForm(props) {
  const { state } = useUserContext();
  const { dispatch } = useModalContext();
  const { incident, refreshCallback } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date())
  const [visibile, setVisibile] = useState(false)

  const changeVisibility = () => {
    setVisibile(!visibile)
  }

  const formAttributes = [
    {
      name: 'name',
      id: 'name',
      labelName: 'Incident Name',
      placeholder: 'Please input name',
      type: 'text',
    },
    {
      name: 'description',
      id: 'description',
      labelName: 'Incident Description',
      placeholder: 'Please input description',
      type: 'text',
    },
    {
      name: 'category',
      id: 'category',
      labelName: 'Incident Category',
      placeholder: 'Please input category',
      type: 'select',
    },
    {
      name: 'subCategory',
      id: 'subCategory',
      labelName: 'Incident Sub Category',
      placeholder: 'Please input sub category',
      type: 'subSelect',
    },
    {
      name: 'originalSource',
      id: 'originalSource',
      labelName: 'Original Source',
      placeholder: 'Please input original source',
      type: 'text',
    },
    {
      name: 'latitude',
      id: 'latitude',
      labelName: 'Latitude',
      placeholder: 'Please input latitude',
      type: 'text',
    },
    {
      name: 'longitude',
      id: 'longitude',
      labelName: 'Longitude',
      placeholder: 'Please input longitude',
      type: 'text',
    },
  ];

   const formDeath = [
    {
      name: 'description',
      id: 'description',
      labelName: 'Injuries',
      placeholder: 'Please input description',
      type: 'text',
    },
    {
      name: 'category',
      id: 'category',
      labelName: 'Deaths',
      placeholder: 'Please input category',
      type: 'text',
    },
  
   ];

   const formCivilian = [
    {
      name: 'civilianInjuries',
      id: 'civilianInjuries',
      labelName: 'Injuries',
      placeholder: '0',
      type: 'text',
    },
    {
      name: 'civilianDeath',
      id: 'civilianDeath',
      labelName: 'Deaths',
      placeholder: '0',
      type: 'text',
    },
  
   ];

   const formEmergency = [
    {
      name: 'emergencyInjuries',
      id: 'emergencyInjuries',
      labelName: 'Injuries',
      placeholder: '0',
      type: 'text',
    },
    {
      name: 'emergencyDeath',
      id: 'emergencyDeath',
      labelName: 'Deaths',
      placeholder: '0',
      type: 'text',
    },
   ]

   const formPolice = [
    {
      name: 'policeInjuries',
      id: 'policeInjuries',
      labelName: 'Injuries',
      placeholder: '0',
      type: 'text',
    },
    {
      name: 'policeDeath',
      id: 'policeDeath',
      labelName: 'Deaths',
      placeholder: '0',
      type: 'text',
    },
   ]

   const formMilitary = [
    {
      name: 'militaryInjuries',
      id: 'militaryInjuries',
      labelName: 'Injuries',
      placeholder: '0',
      type: 'text',
    },
    {
      name: 'militaryDeath',
      id: 'militaryDeath',
      labelName: 'Deaths',
      placeholder: '0',
      type: 'text',
    },
   ]
   const formCriminal = [
    {
      name: 'criminalInjuries',
      id: 'criminalInjuries',
      labelName: 'Injuries',
      placeholder: '0',
      type: 'text',
    },
    {
      name: 'criminalDeath',
      id: 'criminalDeath',
      labelName: 'Deaths',
      placeholder: '0',
      type: 'text',
    },
   ]

  const validate = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    subCategory: Yup.string().required('Required'),
    originalSource: Yup.string().required('Required'),
    latitude: Yup.number().required('Required'),
    longitude: Yup.number().required('Required').typeError('Value must be a decimal e.g. 22.00'),
    civilianInjuries: Yup.number().required('Required'),
    civilianDeath: Yup.number().required('Required'),
    emergencyInjuries: Yup.number().required('Required'),
    emergencyDeath: Yup.number().required('Required'),
    policeInjuries: Yup.number().required('Required'),
    policeDeath: Yup.number().required('Required'),
    militaryInjuries: Yup.number().required('Required'),
    militaryDeath: Yup.number().required('Required'),
    criminalInjuries: Yup.number().required('Required'),
    criminalDeath: Yup.number().required('Required'),


  });

  const mapFormAttributes = (values) => formAttributes.map((attribute) => {
    if (attribute.type === 'text') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <TextField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
            />
          )}
        </Field>
      );
    } if (attribute.type === 'select') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <SelectField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
              options={allCategories}
            />

          )}
        </Field>
      );
    }
    if (attribute.type === 'subSelect') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <SelectField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
              // @ts-ignore
              options={subCategories[values.category] || []}
            />
          )}
        </Field>
      );
    }
  });

  const mapFromDeath = (values) => formDeath.map((deathAttribute) =>{
    if (deathAttribute.type === 'text') {
      return (
        <Field name={deathAttribute.name} key={deathAttribute.id} >
          {({ field, form }) => (
            <TextField
              key={deathAttribute.id}
              id={deathAttribute.name}
              form={form}
              field={field}
              labelName={deathAttribute.labelName}
              placeholder={deathAttribute.placeholder}
            />
          )}
        </Field>
      );
    } if (deathAttribute.type === 'select') {
      return (
        <Field name={deathAttribute.name} key={deathAttribute.id}>
          {({ field, form }) => (
            <SelectField
              key={deathAttribute.id}
              id={deathAttribute.name}
              form={form}
              field={field}
              labelName={deathAttribute.labelName}
              placeholder={deathAttribute.placeholder}
              options={allCategories}
            />

          )}
        </Field>
      );
    }
    if (deathAttribute.type === 'subSelect') {
      return (
        <Field name={deathAttribute.name} key={deathAttribute.id}>
          {({ field, form }) => (
            <SelectField
              key={deathAttribute.id}
              id={deathAttribute.name}
              form={form}
              field={field}
              labelName={deathAttribute.labelName}
              placeholder={deathAttribute.placeholder}
              // @ts-ignore
              options={subCategories[values.category] || []}
            />
          )}
        </Field>
      );
    }
  });

  const mapFormCivilianInjuries= () => formCivilian.map((civilianAttribute => {
    if (civilianAttribute.type === 'text') {
      return (
        <Field name={civilianAttribute.name} key={civilianAttribute.id} >
          {({ field, form }) => (
            <TextField
              key={civilianAttribute.id}
              id={civilianAttribute.name}
              form={form}
              field={field}
              labelName={civilianAttribute.labelName}
              placeholder={civilianAttribute.placeholder}
            />
          )}
        </Field>
      );
    }

  }));
  const mapFormPolice= () => formPolice.map((policeAttribute => {
    if (policeAttribute.type === 'text') {
      return (
        <Field name={policeAttribute.name} key={policeAttribute.id} >
          {({ field, form }) => (
            <TextField
              key={policeAttribute.id}
              id={policeAttribute.name}
              form={form}
              field={field}
              labelName={policeAttribute.labelName}
              placeholder={policeAttribute.placeholder}
            />
          )}
        </Field>
      );
    }

  }));

  const mapFormEmergency= () => formEmergency.map((emergencyAttribute => {
    if (emergencyAttribute.type === 'text') {
      return (
        <Field name={emergencyAttribute.name} key={emergencyAttribute.id} >
          {({ field, form }) => (
            <TextField
              key={emergencyAttribute.id}
              id={emergencyAttribute.name}
              form={form}
              field={field}
              labelName={emergencyAttribute.labelName}
              placeholder={emergencyAttribute.placeholder}
            />
          )}
        </Field>
      );
    }

  }));

  const mapFormMilitary= () => formMilitary.map((militaryAttribute => {
    if (militaryAttribute.type === 'text') {
      return (
        <Field name={militaryAttribute.name} key={militaryAttribute.id} >
          {({ field, form }) => (
            <TextField
              key={militaryAttribute.id}
              id={militaryAttribute.name}
              form={form}
              field={field}
              labelName={militaryAttribute.labelName}
              placeholder={militaryAttribute.placeholder}
            />
          )}
        </Field>
      );
    }

  }));

  const mapFormCriminal= () => formCriminal.map((criminalAttribute => {
    if (criminalAttribute.type === 'text') {
      return (
        <Field name={criminalAttribute.name} key={criminalAttribute.id} >
          {({ field, form }) => (
            <TextField
              key={criminalAttribute.id}
              id={criminalAttribute.name}
              form={form}
              field={field}
              labelName={criminalAttribute.labelName}
              placeholder={criminalAttribute.placeholder}
            />
          )}
        </Field>
      );
    }

  }));

  useEffect(() => {
    if (incident?.reportedDate != null && incident.reportedDate != '') {
      const date = new Date(incident.reportedDate)
      setStartDate(date)
    }
    if (incident?.endedDate != null && incident.endedDate != '') {
      setVisibile(true)
      const date = new Date(incident.endedDate)
      setEndDate(date)
    }
  }, [])

  return (

    <Formik
      initialValues={{
        name: incident?.nameIncident || '',
        description: incident?.descriptionIncident || '',
        category: incident?.categoryIncident || '',
        subCategory: incident?.subCategoryIncident || '',
        originalSource: incident?.originalSourceIncident || '',
        longitude: incident?.longitude || '',
        latitude: incident?.latitude || '',
        civilianInjuries: incident?.civilianInjuries || '',
        civilianDeath: incident?.civilianDeath || '',
        emergencyInjuries: incident?.emergencyInjuries || '',
        emergencyDeath: incident?.emergencyDeath || '',
        policeInjuries: incident?.policeInjuries || '',
        policeDeath: incident?.policeDeath || '',
        militaryInjuries: incident?.militaryInjuries || '',
        militaryDeath: incident?.militaryDeath || '',
        criminalInjuries: incident?.criminalInjuries || '',
        criminalDeath: incident?.criminalDeath || '',



      }}

      validationSchema={validate}
      // eslint-disable-next-line no-unused-vars
      onSubmit={(values, { resetForm }) => {

        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'LOADING',
            message: 'Trying to get your data...',
            title: 'Loading...',
          },
        });

        if (incident && incident.id) {
          const newValues = {
            id: incident.id,
            nameIncident: values.name,
            descriptionIncident: values.description,
            categoryIncident: values.category,
            subCategoryIncident: values.subCategory,
            originalSourceIncident: values.originalSource,
            longitude: Number(values.longitude),
            latitude: Number(values.latitude),
            civilianInjuries: Number(values.civilianInjuries),
            civilianDeath: Number(values.civilianDeath),
            emergencyInjuries: Number(values.emergencyInjuries),
            emergencyDeath: Number(values.emergencyDeath),
            policeInjuries: Number(values.policeInjuries),
            policeDeath: Number(values.policeDeath),
            militaryInjuries: Number(values.militaryInjuries),
            militaryDeath: Number(values.militaryDeath),
            criminalInjuries: Number(values.criminalInjuries),
            criminalDeath: Number(values.criminalDeath),
            reportedDate: startDate,
            endedDate: visibile ? endDate : null
          };
          updateIncident(incident.id, newValues).then((res) => {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'SUCCESS',
                message: 'Successfully Updated Incident',
                title: 'Success!',
              },
            });
            refreshCallback();
          }).catch((err) => {
            console.log(err);
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'FAILED',
                message: err.response.data.message,
                title: 'Failed!',
              },
            });
          });
        } else {
          const newIncident = {
            nameIncident: values.name,
            descriptionIncident: values.description,
            categoryIncident: values.category,
            subCategoryIncident: values.subCategory,
            originalSourceIncident: values.originalSource,
            longitude: Number(values.longitude),
            latitude: Number(values.latitude),
            civilianInjuries: Number(values.civilianInjuries),
            civilianDeath: Number(values.civilianDeath),
            emergencyInjuries: Number(values.emergencyInjuries),
            emergencyDeath: Number(values.emergencyDeath),
            policeInjuries: Number(values.policeInjuries),
            policeDeath: Number(values.policeDeath),
            militaryInjuries: Number(values.militaryInjuries),
            militaryDeath: Number(values.militaryDeath),
            criminalInjuries: Number(values.criminalInjuries),
            criminalDeath: Number(values.criminalDeath),
            adminId: state.user?.uid,
            reportedDate: startDate,
            endedDate: visibile ? endDate : null
          };
          createIncident(newIncident).then((res) => {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'SUCCESS',
                message: 'Successfully Added Incident',
                title: 'Success!',
              },
            });
            resetForm();
          }).catch((err) => {
            console.log(err);
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'FAILED',
                message: err.response.data.message,
                title: 'Failed!',
              },
            });
          });
        } //{mapFormAttributes(values)}
      }}
    >
      {({ values }) => (
        <Form>
              <Box w="full" borderWidth="1px" borderRadius="lg" p={3} mr={3} mb={3}>
      <Flex alignItems="center">
        <Box flex="1">
        <Flex mt={4} ml={2}>
          </Flex>
         
          <Flex mt={4} alignItems="center" >
            <Text>Civilian        :</Text>
            <Flex  ml="auto">  
               {mapFormCivilianInjuries(values)}
            </Flex>
           
          
          </Flex>

          <Flex mt={4} alignItems="center" >
            <Text>Emergency Service:</Text>
            <Flex  ml="auto">  
               {mapFormEmergency(values)}
            </Flex>
           
          
          </Flex>

          <Flex mt={4} alignItems="center" >
            <Text>Police            :</Text>
            <Flex  ml="auto">  
               {mapFormPolice(values)}
            </Flex>
           
          
          </Flex>

          <Flex mt={4} alignItems="center" >
            <Text>Military          :</Text>
            <Flex  ml="auto">  
               {mapFormMilitary(values)}
            </Flex>
           
          
          </Flex>

          <Flex mt={4} alignItems="center" >
            <Text>Criminal          :</Text>
            <Flex  ml="auto">  
               {mapFormCriminal(values)}
            </Flex>
           
          
          </Flex>
        
          

          
        </Box>
      </Flex>


    </Box>
            

          <Button
            isFullWidth
            my={3}
            bg="#18587c"
            type="submit"
          >
            <Text color = 'white'>Submit</Text>
            
          </Button>
        </Form>
        
      )}
    </Formik>
  );
}

export default CasualityForm;
