import {
  useState, useEffect, useCallback, useRef
} from 'react';
import React from 'react';

import {
  Flex, Heading, CircularProgress, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Box,
  Text
} from '@chakra-ui/react';
import InboardLayout from 'layout/InboardLayout';
import { useUserContext } from 'context/userContext';
import IncidentCard from 'components/inboard/incident/IncidentCard';
import IncidentForm from 'components/inboard/incident/IncidentForm';
import CasualityForm from 'components/inboard/incident/casualityForm';
import { getMyIncidents, deleteIncident, downloadIncidents, getMyIncidentsCount, getIncidentCountPeriod } from 'api/incidents';
import { useModalContext } from 'context/modalContext';
import ModalFilterDate from 'components/inboard/incident/ModalFilterDate';
import { useMapContext } from 'context/mapContext';
import SEO from 'components/SEO'
import {Line} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import faker, { date } from 'faker';
import { FiDownload } from 'react-icons/fi';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Incident Graphic',
    },
  },
  scales : {
    y: {
      beginAtZero:true
    }
  }
};

export const options2 = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Incident Per Month',
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      beginAtZero: true
    }
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const data2 = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    }
  ],
};

const data = {
	labels: [
		"Today",
    "Week",
    "Month",
    "Year"
	],
	datasets: [
		{
			label: "Total Incidents",
			fill: true,
			backgroundColor: "rgba(75,192,192,0.4)",
			borderColor: "rgba(75,192,192,1)",
			data: [2,5,5,5],
		},
	],
};

const mapMyIncidents = (
  incidentsArray,
  toggle,
  setIncident,
  setIsDeleting,
  setIsAdd,
) => {
  const edtiMyIncident = (incident) => {
    setIsDeleting(true);
    setIsAdd(false)
    setIncident(incident);
    toggle(true);
  };

  const addMyIncident = (incident) => {
    setIncident(incident);
    setIsDeleting(false);
    setIsAdd(false);
    toggle(true);
  }

  const deleteMyIncident = (incident) => {
    setIsDeleting(true);
    setIsAdd(true);
    setIncident(incident);
    toggle(true);
  };

  if (incidentsArray) {
    return incidentsArray.map((incident) => (
      <IncidentCard
        key={incident.id}
        incident={incident}
        editCallBack={edtiMyIncident}
        deleteCallBack={deleteMyIncident}
        addCallBack={addMyIncident}
      />
    ));
  }

  return <></>;
};

function MyIncident() {
  const [incidents, setMyIncidents] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [incident, setIncident] = useState(null);
  const [countInsidents, setCountInsidents] = useState(null);
  const [countInsidentsPeriod, setCountInsidentsPeriod] = useState(null);
  const { state } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const { dispatch } = useModalContext();
  const { state: mapState } = useMapContext();

  let ref = useRef(null);
  const downloadChart = useCallback(() => {
    const link = document.createElement('a');
    link.download = "Incident Chart.png";
    link.href = ref.current.toBase64Image();
    link.click();
  }, []);

  let ref2 = useRef(null);
  const downloadChart2 = useCallback(() => {
    const link = document.createElement('a');
    link.download = "Incident Per Month Chart.png";
    link.href = ref2.current.toBase64Image();
    link.click();
  }, []);

  const refreshIncidents = (date) => {
    // console.log(date)
    if (state.user) {
      if (state.user.uid) {
        getMyIncidents(state.user.uid, date).then((res) => {
          setMyIncidents(res.data.data.incidents);
        }).catch((err) => {
          console.log(err);
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              show: true,
              status: 'FAILED',
              message: 'Something went wrong',
              title: 'Failed!',
            },
          });
        });

        var perMonth = [0,0,0,0,0,0,0,0,0,0,0,0];
        getMyIncidentsCount(state.user.uid, date).then((res) => {
          res.data.data.incidents.forEach(incident => {
            var month = new Date(incident.date_trunc);
            perMonth[month.getMonth()] = incident.count;
          });
          
          setCountInsidents(perMonth);
        }).catch((err) => {
          console.log(err);
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              show: true,
              status: 'FAILED',
              message: 'Something went wrong',
              title: 'Failed!',
            },
          });
        });

        var period = [0,0,0,0];
        getIncidentCountPeriod(state.user.uid, date).then((res) => {
          
          if (res.data.data.day.length > 0)
          {
            period[0] = res.data.data.day[0].count;
          }

          if (res.data.data.week.length > 0)
          {
            period[1] = res.data.data.week[0].count;
          }

          if (res.data.data.month.length > 0)
          {
            period[2] = res.data.data.month[0].count;
          }

          if (res.data.data.year.length > 0)
          {
            period[3] = res.data.data.year[0].count;
          }

          setCountInsidentsPeriod(period);
        }).catch((err) => {
          console.log(err);
          dispatch({
            type: 'SHOW_MODAL',
            payload: {
              show: true,
              status: 'FAILED',
              message: 'Something went wrong',
              title: 'Failed!',
            },
          });
        });
      }
    }
    setIsOpen(false);
  };

  const data = {
    labels: [
      "Today",
      "Week",
      "Month",
      "Year"
    ],
    datasets: [
      {
        label: "Total Incidents",
        fill: true,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: countInsidentsPeriod
      },
    ],
  };

  const data2 = {
    labels,
    datasets: [
      {
        label: 'Total Incidents',
        data: countInsidents,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ],
  };

  const deleteIncidentById = (incidentId) => {
    dispatch({
      type: 'SHOW_MODAL',
      payload: {
        show: true,
        status: 'LOADING',
        message: 'Trying to get your data...',
        title: 'Loading...',
      },
    });
    if (incidentId) {
      deleteIncident(incidentId).then((res) => {
        setIsOpen(false);
        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'SUCCESS',
            message: 'Successfully Deleted Incident',
            title: 'Success!',
          },
        });
      }).catch((err) => {
        console.log(err);
        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'FAILED',
            message: 'Something went wrong',
            title: 'Failed!',
          },
        });
      });
    }
  };

  const downloadIncident = () => {
    var date = new Date();
    var year = date.getFullYear();
    const data = {
      year : year
    };
    if (data) {
      downloadIncidents(data).then((res) => {
        const headerval = res.headers['content-disposition'];
        let filename = '';
        if (!headerval)
        {
          filename = 'incident.xlsx';
        }
        else{
          filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        }
        
        filename = decodeURI(filename);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      }).catch((err) => {
        console.log(err);
        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'FAILED',
            message: 'Something went wrong',
            title: 'Failed!',
          },
        });
      });
    }
  };


  useEffect(() => {
    setIsLoading(true);
    try {
      refreshIncidents(mapState.date);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, [mapState.date]);

  if (isLoading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }
  return (
    <div>
      
      <SEO title="My Incidents - Asset Monitoring" />
      <Flex wrap="wrap">
        <Box width="250px" ml="auto">
          <Button 
              leftIcon={<FiDownload />} 
              colorScheme="white" 
              isFullWidth mt="3" 
              variant="outline"
              onClick={() => { downloadIncident(date); }}
          >
            {'    '}
            Download Incident
            {' '}
          </Button>
        </Box>
        <Box width="100px" ml="2">
          <ModalFilterDate />
        </Box>
      </Flex>
      
      <Flex mt={6} wrap="wrap" style={{ maxHeight: '78vh', overflow: 'auto' }}>

        <Box w="sm" borderWidth="1px" borderRadius="lg" p={3} mr={3} mb={3}>
          <Button leftIcon={<FiDownload />} 
              colorScheme="white" 
              isFullWidth mt="3" 
              variant="outline" 
              onClick={() => {downloadChart();}}>
              <Text color="white">Download Chart</Text>
          </Button>
          <Line options={options} data={data} width={25} height={25} ref={ref}/>
        </Box>
        <Box w="sm" borderWidth="1px" borderRadius="lg" p={3} mr={3} mb={3}>
          <Button leftIcon={<FiDownload />} 
              colorScheme="white" 
              isFullWidth mt="3" 
              variant="outline" 
              onClick={() => {downloadChart2();}}>
              <Text color="white">Download Chart</Text>
          </Button>
          <Line options={options2} data={data2} width={25} height={25} ref={ref2} />
        </Box>
      </Flex>
      <Flex mt={6} wrap="wrap" style={{ maxHeight: '78vh', overflow: 'auto' }}>
        {mapMyIncidents(
          incidents,
          setIsOpen,
          setIncident,
          setIsDeleting,
          setIsAdd,
        )}
      </Flex>
      {/* Edit Modal */}
      <Modal isOpen={isOpen} onClose={() => { }} size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {
              isDeleting ? isAdd? 'Delete' : 'Edit' : 'Add Casualty'
            }
            {' '}
            Incident
            {' '}
            {incident?.nameIncident}
          </ModalHeader>
          <ModalBody>

            {
              isDeleting ? isAdd
                ? (
                  <div>
                    <div>
                      Are you sure you want to Delete this incident?
                    </div>
                    <Button
                      onClick={() => { deleteIncidentById(incident?.id); }}
                      bg="#e53030"
                      my="3"
                      isFullWidth
                    ><Text color="white">Delete
                    </Text>
                    </Button>
                  </div>
                )
                : <IncidentForm incident={incident} refreshCallback={() => { refreshIncidents(mapState.date) }} /> 
                : <CasualityForm incident={incident} refreshCallback={() => { refreshIncidents(mapState.date) }} />
            }
            <Button
              isFullWidth
              bg={isDeleting ? isAdd ? "#186689" : "red" : "red"}
              variant="outline"
              mb={4}
              onClick={() => { setIsOpen(false); }}
            >
              <Text color="white">Close</Text>
            </Button>
          </ModalBody>

        </ModalContent>
      </Modal>
      {/* Delete Modal Confirmation */}
    </div>

  );
}

MyIncident.layout = function getLayout(page) {
  return (
    <InboardLayout>
      {page}
    </InboardLayout>
  );
};
export default MyIncident;
