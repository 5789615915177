import React from 'react';
import {
    Box, Text, Icon, Flex
} from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/color-mode';
import {
    FiMove
} from 'react-icons/fi';


function IncidentPopup(props) {
    const { incident } = props
    const { colorMode } = useColorMode();

    return (
        <Box
            bg={colorMode === 'light' ? 'white' : '#171923'}
            textAlign="start"
            borderRadius="lg"
            boxShadow="lg"
            lat={incident.latitude}
            lng={incident.longitude}
            w="md"
            style={{
                maxWidth: '280px',
                position: 'absolute',
                bottom: '250%',
                cursor: 'pointer',
                left: '50%',
                transform: 'translateX(-50%)',
                marginBottom: '25px'
            }}
            py={2}
            px={4}
            _after={{
                content: '""',
                position: 'absolute',
                top: '100%',
                left: '50%',
                marginLeft: '-5px',
                borderWidth: '5px',
                borderStyle: 'solid',
                borderColor: `${colorMode === 'light' ? 'white' : '#171923'} transparent transparent transparent`,
            }}
        >
            <Flex alignItems="center">
                <Icon as={FiMove} fontSize="16" />
                <Text fontWeight="semibold" fontSize="16" my="2" ml="3">
                    Approximate Location of the Incident
                </Text>
            </Flex>
        </Box >
    )
}

export default IncidentPopup
