import React from 'react';

import {
  FormControl, FormLabel, Input, FormErrorMessage, Button, Flex
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';

function TextField({
  form, field, id, labelName, placeholder, type,
}) {
  const fileInput = React.createRef();
  const formikProps = useFormikContext();
  return (
    // eslint-disable-next-line no-bitwise
    <FormControl isInvalid={(form.errors[id] && form.touched[id])} mt={4}>
      <FormLabel htmlFor={id}>{labelName}</FormLabel>
      
      <Input
        {...field}
        id={id}
        display="none"
        placeholder={placeholder}
        type={type || 'file'}
        value={undefined}
        ref={fileInput}
        accept="image/png, image/gif, image/jpeg" 
        // onChange={formikProps.handleChange}
        onChange={(event) => {
          formikProps.setFieldValue("image", event.currentTarget.files[0])
        }}
      />
      <Flex>
        <Button onClick={() => fileInput.current.click()}>Choose File</Button>
        <Input disabled="disabled" marginLeft="13px" value={formikProps.getFieldProps("image").value ? formikProps.getFieldProps("image").value.name || "Error" : "No file chosen"}></Input>

      </Flex>
      <FormErrorMessage>
        {labelName}
        {' '}
        {form.errors[id]}
      </FormErrorMessage>
    </FormControl>
  );
}

export default TextField;
