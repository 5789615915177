import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Box,
  Link,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextField from 'components/inboard/base/TextField';
import { useModalContext } from 'context/modalContext';
import { resetPassword } from 'api/auth';

const formAttributes = [
  {
    name: 'email',
    id: 'email',
    labelName: 'Email',
    placeholder: 'Please input your email',
    type: 'text',
  },
];

const validate = Yup.object().shape({
  email: Yup.string().required('Required').email(),
});

const mapFormAttributes = () => formAttributes.map((attribute) => (
  <Field name={attribute.name} key={attribute.id}>
    {({ field, form }) => (
      <TextField
        key={attribute.id}
        id={attribute.name}
        form={form}
        field={field}
        labelName={attribute.labelName}
        placeholder={attribute.placeholder}
      />
    )}
  </Field>
));

function ModalForgetPassword() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { dispatch } = useModalContext();

  return (
    <>
      {/* <Button colorScheme="teal" onClick={onOpen} variant="outline"> */}
      {' '}
      <Link onClick={onOpen}>
        Forgot Password
      </Link>
      {/* </Button> */}

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={8}>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={validate}
              // eslint-disable-next-line no-unused-vars
              onSubmit={(values, actions) => {
                dispatch({
                  type: 'SHOW_MODAL',
                  payload: {
                    show: true,
                    status: 'LOADING',
                    message: 'Trying to get your data...',
                    title: 'Loading...',
                  },
                });
                const data = {
                  email: values.email,
                };
                resetPassword(data).then((res) => {
                }).catch((err) => {
                  console.log(err);
                });
              }}
            >
              {(formik) => (
                <Form>
                  {mapFormAttributes()}
                  <Box flexDirection="row" mt={8}>
                    <Button
                      colorScheme="teal"
                      onClick={onClose}
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="teal"
                      ml={3}
                      isLoading={formik.isSubmitting}
                      type="submit"
                    >
                      Submit
                    </Button>

                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalForgetPassword;
