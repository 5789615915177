import React from 'react';

import {
  FormControl, FormLabel, Input, FormErrorMessage,
} from '@chakra-ui/react';

function TextField({
  form, field, id, labelName, placeholder, type, visibility = true
}) {
  return (
    // eslint-disable-next-line no-bitwise
    <FormControl isInvalid={(form.errors[id] && form.touched[id])} mt={4}>
      <FormLabel 
      htmlFor={id}
      display={visibility ? 'block':'none'}
      >{labelName}</FormLabel>
      <Input
        {...field}
        id={id}
        placeholder={placeholder}
        type={type || 'text'}
        display={visibility ? 'block':'none'}
      />
      <FormErrorMessage>
        {labelName}
        {' '}
        {form.errors[id]}
      </FormErrorMessage>
    </FormControl>
  );
}

export default TextField;
