/* eslint-disable default-case */

// eslint-disable-next-line consistent-return
const reducer = (state, action) => {
  switch (action.type) {
    case 'STORE_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'REMOVE_USER':
      return {
        ...state,
        user: {},
      };
    case 'STORE_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
  }
};

export default reducer;
