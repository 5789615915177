/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import {
  useState, useEffect, useCallback, useRef
} from 'react';
import {
  Container, Box,
} from '@chakra-ui/react';
import SubCategoryForm from 'components/inboard/category/SubCategoryForm';
import InboardLayout from 'layout/InboardLayout';
import SEO from 'components/SEO'
import { getAllCategories } from 'api/categories';
import { useModalContext } from 'context/modalContext';
import { useUserContext } from 'context/userContext';

function AddSubCategory() {
  const { dispatch } = useModalContext();
  const [isLoading, setIsLoading] = useState(true);
  const [ categories, setMyCategories] = useState([]);
  const [ trigger, setTriggerLoading] = useState([false]);
  const { state } = useUserContext();

  useEffect(() => {
    setIsLoading(true);
    try {
      getAllCategory();
        // setMyCategories([]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }, trigger);

  const getAllCategory = () => {
    if (state.user) {
      getAllCategories().then((res) => {
        let data = res.data.data.categories.map(category => ({
          id:category.id,
          text: category.name,
          value: category.id, 
          selected: category.name
        }));
        data.splice(0,0,{
          id: "",
          text: "Add New Category",
          value: "new category", 
          selected: "Add New Category"
        });
        setMyCategories(data);
      }).catch((err) => {
        console.log(err);
        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'FAILED',
            message: 'Something went wrong',
            title: 'Failed!',
          },
        });
      });
    }
  }

  return (
    <Container maxW="container.lg" mt={6}>
        <SEO title="Add Categories - Asset Monitoring" />
      <Box borderWidth="1px" borderRadius="lg" px="8" pb="6">
        <SubCategoryForm listCategory={categories}/>
      </Box>
    </Container>
  );
}

AddSubCategory.layout = function getLayout(page) {
  return (
    <InboardLayout>
      {page}
    </InboardLayout>
  );
};

export default AddSubCategory;
