/* eslint-disable max-len */
import React from 'react';
import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { mapStyles } from 'styles/mapStyles';
import { getAllIncidents } from 'api/incidents';
import { getAllAssets } from 'api/assets';
import calculateDistanceBetweenCoordinates from 'utils/calculateDistance';
import { useMapContext } from 'context/mapContext';
import { useUserContext } from 'context/userContext';
import { useModalContext } from 'context/modalContext';
import IncidentMarker from 'components/inboard/map/marker/IncidentMarker';
import AssetMarker from 'components/inboard/map/marker/AssetMarker';
import IncidentBoxMap from 'components/inboard/incident/IncidentBoxMap';
import DetailWindow from 'components/inboard/map/DetailWindow';
import IncidentPopup from 'components/inboard/incident/IncidentPopup';
import SEO from 'components/SEO'
import useSupercluster from "use-supercluster";
const Marker = ({ children }) => children;


function Map() {
  const [incidents, setIncidents] = useState([]);
  const [allIncidents, setAllIncidents] = useState([]);
  const [boxIncidents, setBoxIncidents] = useState([]);
  const [assets, setAssets] = useState([]);
  const [points, setPoints] = useState([]);
  const [isolatedMap, setIsolatedMap] = useState({
    map: '',
    maps: '',
  });
  const [polylines, setPolylines] = useState([])
  const [detailAssets, setDetailAssets] = useState({
    clientId: '',
    id: '',
    province: '',
    region: '',
    assetName: '',
    address: '',
    longitude: 0,
    latitude: 0,
  });
  const { state, dispatch: mapDispatch } = useMapContext();
  const { state: userState } = useUserContext();
  const { dispatch: modalDispatch } = useModalContext();
  const [zoomLevel, setZoomLevel] = useState(5);
  const [bounds, setBounds] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: -0.789275, lng: 113.921327 })
  const [mapHeight, setMapHeight] = useState('93vh')
  const [detailIncident, setDetailIncident] = useState({
    createdBy: '',
    id: '',
    nameIncident: '',
    descriptionIncident: '',
    categoryIncident: '',
    subCategoryIncident: '',
    originalSourceIncident: '',
    whatsapp: '',
    longitude: 0,
    latitude: 0,
  });

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoomLevel,
    options: { radius: 75, maxZoom: 18 }
  });
  // Render polylines when marker is clicked
  const renderPolylines = (map, maps, incident) => {
    const nonGeodesicPolyline = new maps.Polyline({
      path: [
        { lat: incident.latitude, lng: incident.longitude },
        { lat: incident.closestAsset?.assetLatitude, lng: incident.closestAsset?.assetLongitude },
      ],
      geodesic: false,
      strokeColor: '#e4e4e4',
      strokeOpacity: 0.7,
      strokeWeight: 3,
      id: incident.id
    });
    const newPolylines = polylines
    newPolylines.push(nonGeodesicPolyline)
    setPolylines(newPolylines)
    nonGeodesicPolyline.setMap(map);
  };

  // When asset Details is closed
  const resetDetails = () => {
    onChildMouseDown();
    setDetailIncident(
      {
        createdBy: '',
        id: '',
        nameIncident: '',
        descriptionIncident: '',
        categoryIncident: '',
        subCategoryIncident: '',
        originalSourceIncident: '',
        whatsapp: '',
        longitude: 0,
        latitude: 0,
      }
    )
    setDetailAssets({
      clientId: '',
      id: '',
      province: '',
      region: '',
      assetName: '',
      address: '',
      longitude: 0,
      latitude: 0,
    });
    // setZoomLevel(5)
    
    onChildMouseUp();
  };

  const resetIncidentPopup = (incident) => {
    // console.log(polylines)
    const deletePolyline = polylines.filter((polyline) => {
      return polyline.id == incident.id
    })
    deletePolyline.forEach((popup) => {
      popup.setMap(null)
    })
    const newPolylines = polylines.filter((polyline) => {
      return polyline.id != incident.id
    })
    setPolylines(newPolylines)
    const newBoxIncidents = boxIncidents.map((box) => {
      if (box.id == incident.id) {
        return {
          ...box,
          on: false
        }
      } else {
        return {
          ...box
        }
      }
    })
    setBoxIncidents(newBoxIncidents)
  }

  const viewPopup = (detail) => {
    const filterIncidents = boxIncidents.filter(item => {
      return item.id == detail.id
    })
    if (filterIncidents[0].on) return
    const currentIncidents = boxIncidents.map(item => {
      if (item.id == detail.id) {
        return {
          ...item,
          on: true,
        }
      }
      return {
        ...item,
        on: false,
      }
    });
    setBoxIncidents(currentIncidents)
    if (detail.closestAsset) {
      renderPolylines(isolatedMap.map, isolatedMap.maps, detail);
    }
  };

  const viewDetailIncident = (detail) => {
    setMapHeight('49vh')
    setMapCenter({
      lat: (detail.originalLatitude || 0),
      lng: (detail.originalLongitude || 0)
    })
    setZoomLevel(9)
    setDetailIncident(detail)
  }

  const viewDetailAsset = (detail) => {
    // resetDetails();
    setMapCenter({
      lat: detail.latitude,
      lng: detail.longitude
    })
    // setZoomLevel(10)
    setDetailAssets(detail);
  };
  const zoomOnClick = (latitude, longitude, zoom = 15) => {
    // resetDetails();
    setMapCenter({
      lat: latitude,
      lng: longitude
    })
    setZoomLevel(zoom)
  };



  const getMarkers = async (date) => {
    modalDispatch({
      type: 'SHOW_MODAL',
      payload: {
        show: true,
        status: 'LOADING',
        message: 'Trying to get your data...',
        title: 'Loading...',
      },
    });
    let userId = '';
    const role = userState.user?.role;
    if (role === 'user') {
      userId = userState.user?.uid;
    }
    try {
      const assets = await getAllAssets(userId)
      setAssets(assets.data.data.assets);
      let points = assets.data.data.assets.map(assets => ({
        type: "Assets",
        properties: { 
          cluster: false, 
          assets: assets
          // address: assets.address,
          // assetName: assets.assetName,
          // client: assets.client,
          // clientId: assets.clientId,
          // createdAt: assets.createdAt,
          // id: assets.id,
          // latitude: assets.latitude,
          // longitude: assets.longitude,
          // province: assets.province,
          // region: assets.region,
          // updatedAt: assets.updatedAt
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(assets.longitude),
            parseFloat(assets.latitude)
          ]
        }
      }))
      const incidents = await getAllIncidents(date)

      console.error(incidents);
      const mappedIncidents = incidents.data.data.incidents.map((data) => {
        const distancesFromAssets = [];
        assets.data.data.assets.forEach((asset) => {
          const distanceFromAsset = calculateDistanceBetweenCoordinates(
            data.longitude,
            data.latitude,
            asset.longitude,
            asset.latitude,
          );
          distancesFromAssets.push({
            id: asset.id,
            assetName: asset.assetName,
            assetLongitude: asset.longitude,
            assetLatitude: asset.latitude,
            distanceFromAsset,
          });
        });
        const closestAsset = distancesFromAssets.sort(
          (a, b) => a.distanceFromAsset - b.distanceFromAsset,
        );
        return {
          ...data,
          closestAsset: closestAsset[0],
        };
      })
      setPoints(points);
      setIncidents(mappedIncidents);
      setAllIncidents(mappedIncidents);
      const popupIncidents = mappedIncidents.map((item) => {
        return {
          ...item,
          on: false,
          originalLatitude: item.latitude,
          originalLongitude: item.longitude
        }
      })
      // console.log(popupIncidents)
      setBoxIncidents(popupIncidents)
      modalDispatch({
        type: 'CLOSE_MODAL',
        payload: {},
      });
    } catch (err) {
      // console.log(err)
      modalDispatch({
        type: 'SHOW_MODAL',
        payload: {
          show: true,
          status: 'FAILED',
          message: 'Something went wrong',
          title: 'Failed!',
        },
      });
    }
  };

  // Change types of incidents based on the dates reported
  useEffect(() => {
    mapDispatch({
      type: 'REMOVE_MAP_FILTER',
      payload: {},
    });
    getMarkers(state.date);
  }, [state.date]);

  // Change types of incidents based on categories
  useEffect(() => {
    if (state.selectedCategories.length > 0) {
      // @ts-ignore
      const newIncident = allIncidents.filter((incident) => state.selectedCategories.includes(incident.subCategoryIncident));
      setIncidents(newIncident);
      resetDetails();
    } else {
      setIncidents(allIncidents);
    }
  }, [state.selectedCategories]);

  // Set Map markers draggable or not (For Popups)
  const [draggable, setDraggable] = useState(true)
  // Set Map markers draggable or not (For Popups)
  const onChildMouseDown = () => {
    setDraggable(false)
  }
  // Set Map markers draggable or not (For Popups)
  const onChildMouseUp = () => {
    setDraggable(true)
  }
  // Set Map markers draggable or not (For Popups)
  const onChildMouseMove = (key, marker, newCoords) => {
    // console.log(key)
    // console.log(marker)
    // console.log(newCoords)
    // console.log(marker.incident.id)
    // console.log(key);
    // console.log(marker);
    if (key.split('-')[0] != 'ib') return
    const currentBoxes = boxIncidents.map(item => {
      if (item.id == marker.incident.id) {
        return {
          ...item,
          latitude: newCoords.lat,
          longitude: newCoords.lng
        }
      }
      return {
        ...item
      }
    });
    setBoxIncidents(currentBoxes)
  }

  return (
    <>
      <SEO title="Maps - Asset Monitoring" />
      <div style={{ height: mapHeight }}>
        <GoogleMapReact
          draggable={draggable}
          key="one"
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GMAPS_API_KEY || '',
            // key: '',
          }}
          zoom={zoomLevel}
          center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
          margin={[10, 10, 10, 10]}
          onChange={(e) => {
            setZoomLevel(e.zoom)
            setBounds([
              e.bounds.nw.lng,
              e.bounds.se.lat,
              e.bounds.se.lng,
              e.bounds.nw.lat
            ]);
            setMapCenter({
              lat: e.center.lat,
              lng: e.center.lng
            })
          }}
          options={{
            mapTypeId: state.options || 'roadmap',
            disableDefaultUI: true,
            zoomControl: true,
            styles: mapStyles,
          }}
          onGoogleApiLoaded={({ map, maps }) => setIsolatedMap({ map, maps })}
          onChildMouseDown={onChildMouseDown}
          onChildMouseUp={onChildMouseUp}
          onChildMouseMove={onChildMouseMove}
        >
          {
            detailIncident.id !== '' && (
              <IncidentPopup
                incident={detailIncident}
                lat={detailIncident.originalLatitude || 0}
                lng={detailIncident.originalLongitude || 0}
              />
            )
          }

          {/* {
        incidents.map((incident) => (
          // @ts-ignore
          incident.closestAsset?.distanceFromAsset < state.alertRadius
          && (
            <AssetAlert
              lat={Number(incident.latitude)}
              lng={Number(incident.longitude)}
              incident={incident}
            />
          )))
      } */}
          {/* Map Incidents marker */}
          {
            boxIncidents.map((incident) => (
              (incident.on && detailIncident.id !== incident.id) && < IncidentBoxMap
                key={`ib-${incident.id}`}
                lat={Number(incident.latitude)}
                lng={Number(incident.longitude)}
                incident={incident}
                // drag={onChildMouseDown}
                disableDragMap={onChildMouseDown}
                enableDragMap={onChildMouseUp}
                resetCallBack={resetIncidentPopup}
                viewAllDetails={viewDetailIncident}
              />
            ))
          }

          {

          }
          {
            incidents.map((incident) => (
              <IncidentMarker
                lat={Number(incident.latitude)}
                lng={Number(incident.longitude)}
                key={incident.id}
                incident={incident}
                // detailIncident={detailIncident}
                viewPopup={viewPopup}
                resetDetails={resetDetails}
                zoomAsset={zoomOnClick}
              />
            ))
          }

          {
            
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount
            } = cluster.properties;
            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / assets.length) * 20}px`,
                      height: `${10 + (pointCount / assets.length) * 20}px`
                    }}
                    onClick={() =>{
                      zoomOnClick(latitude, longitude, 13)
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }
            
            if(cluster.properties.assets){
              return (
                  <AssetMarker
                    key={cluster.properties.assets.id}
                    lat={Number(cluster.properties.assets.latitude)}
                    lng={Number(cluster.properties.assets.longitude)}
                    asset={cluster.properties.assets}
                    detailAsset={detailAssets}
                    viewDetailAsset={viewDetailAsset}
                    zoomAsset={zoomOnClick}
                    resetDetails={resetDetails}
                    disableDragMap={onChildMouseDown}
                    enableDragMap={onChildMouseUp}
                  />
              );
            } 
          })
          }

          {/* Map assets marker */}
          {/* {
            assets.length > 0 && assets.map((asset) => (
              <AssetMarker
                key={asset.id}
                lat={Number(asset.latitude)}
                lng={Number(asset.longitude)}
                asset={asset}
                detailAsset={detailAssets}
                viewDetailAsset={viewDetailAsset}
                resetDetails={resetDetails}
              />
            ))
          } */}

        </GoogleMapReact >
      </div>
      {
        detailIncident.id != '' &&
        <DetailWindow
          incident={detailIncident}
          resetIncident={resetDetails}
          setMapHeight={setMapHeight}
          setZoomLevel={setZoomLevel}
        //setDraggable={setDraggable}

        />

      }
    </>
  );
}

export default Map;
