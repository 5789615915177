import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextField from 'components/inboard/base/TextField';
import { changePassword } from 'api/auth';
import { useModalContext } from 'context/modalContext';
import { useUserContext } from 'context/userContext';

const formAttributes = [
  {
    name: 'password',
    id: 'password',
    labelName: 'Password',
    placeholder: 'Please input password',
    type: 'password',
  },
  {
    name: 'newPassword',
    id: 'newPassword',
    labelName: 'New Password',
    placeholder: 'Please input password',
    type: 'password',
  },
  {
    name: 'confirmPassword',
    id: 'confirmPassword',
    labelName: 'Confirm New Password',
    placeholder: 'Please confirm your password',
    type: 'password',
  },
];

const validate = Yup.object().shape({
  password: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'must match with New Password'),
});

const mapFormAttributes = () => formAttributes.map((attribute) => (
  <Field name={attribute.name} key={attribute.id}>
    {({ field, form }) => (
      <TextField
        key={attribute.id}
        id={attribute.name}
        form={form}
        field={field}
        labelName={attribute.labelName}
        placeholder={attribute.placeholder}
        type="password"
      />
    )}
  </Field>
));

function ModalChangePassword() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { dispatch } = useModalContext();

  const { state } = useUserContext()

  const changePasswordHandler = (id, oldPassword, newPassword) => {
    const payload = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    changePassword(id, payload).then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <>
      <Button bg="#18587c" onClick={onOpen}>
        <Text color='white'>Change Password</Text>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={8}>
            <Formik
              initialValues={{
                password: '',
                newPassword: '',
                confirmPassword: '',
              }}
              validationSchema={validate}
              // eslint-disable-next-line no-unused-vars
              onSubmit={(values, actions) => {
                const payload = {
                  old_password: values.password,
                  new_password: values.newPassword,
                };
                changePassword(
                  state.user?.uid || '',
                  payload
                ).then((res) => {
                  dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      show: true,
                      status: 'SUCCESS',
                      message: 'Successfully Changed Password',
                      title: 'Success!',
                    },
                  });
                  console.log(res.data);
                  actions.setSubmitting(false)
                  onClose()
                }).catch((err) => {
                  console.log(err.response.data.message)
                  dispatch({
                    type: 'SHOW_MODAL',
                    payload: {
                      show: true,
                      status: 'FAILED',
                      message: err.response.data.message,
                      title: 'Failed!',
                    },
                  });
                  actions.setSubmitting(false)
                  console.log(err);
                });
              }}
            >
              {(formik) => (
                <Form>
                  {mapFormAttributes()}
                  <Box flexDirection="row" mt={8}>
                    <Button
                      colorScheme="teal"
                      onClick={onClose}
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="teal"
                      ml={3}
                      isLoading={formik.isSubmitting}
                      type="submit"
                    >
                      Submit
                    </Button>

                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalChangePassword;
