import React from 'react';

import {
  createContext,
  useContext,
  useReducer,
} from 'react';

import modalReducer from './modalReducer';

// Initial State
const initialState = {
  modal: {
    show: false,
    status: '',
    title: '',
    message: '',
  },
};

const modalContext = createContext({
  state: initialState,
  dispatch: () => {},
});

export const ModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  return (
    <modalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </modalContext.Provider>
  );
};

export const useModalContext = () => {
  const { state, dispatch } = useContext(modalContext);
  return { state, dispatch };
};
