import React from 'react';
import {
  Flex,
  Text,
  Icon,
  Link,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
import { Link as NextLink } from 'react-router-dom';

export default function NavItem({
  icon, title, active, navSize, link,
}) {
  return (
    <Flex
      mt={30}
      flexDir="column"
      w="100%"
      alignItems={navSize === 'small' ? 'center' : 'flex-start'}
    >
      <Menu placement="right">
        <Link
            backgroundColor={active ? '#18587c' : ''}
            color={active ? 'white' : ''}
            p={3}
            borderRadius={8}
            style={{ textDecoration: 'none' }}
            w={navSize === 'large' ? '100%' : ''}
          >
          <NextLink to={link} style={{width: '100%'}}>
          <MenuButton w="100%">
                <Flex>
                  <Icon as={icon} fontSize="xl" color={active ? '#82AAAD' : 'gray.500'} style={{marginTop: '2px'}} />
                  <Text ml={5} display={navSize === 'small' ? 'none' : 'flex'}>{title}</Text>
                </Flex>
              </MenuButton>
          </NextLink>

        </Link>
      </Menu>
    </Flex>
  );
}
