import React from 'react';

import {
  createContext,
  useContext,
  useReducer,
} from 'react';

import mapReducer from './mapReducer';

// Initial State
const date = new Date();
date.setDate(date.getDate() - 2);
const initialState = {
  options: '',
  alertRadius: 25,
  selectedCategories: [],
  date: {
    start: date,
    end: new Date(),
  },
};

const mapContext = createContext({
  state: initialState,
  dispatch: () => {},
});

export const MapContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mapReducer, initialState);
  return (
    <mapContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </mapContext.Provider>
  );
};

export const useMapContext = () => {
  const { state, dispatch } = useContext(mapContext);
  return { state, dispatch };
};
