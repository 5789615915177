import FlightIncident from 'assets/FlightIncident';
import Assault from 'assets/Assault';
import DrugTraf from 'assets/DrugTraf';
import Others from 'assets/Others';
import Robbery from 'assets/Robbery';
import Earthquake from 'assets/Earthquake';
import Flood from 'assets/Flood';
import Hailstorm from 'assets/Hailstorm';
import Landslide from 'assets/Landslide';
import Tsunami from 'assets/Tsunami';
import Fire from 'assets/Fire';
import Forest from 'assets/Forest';
import IndustrialPollution from 'assets/IndustrialPollution';
import International from 'assets/International';
import PPKM from 'assets/PPKM';
import Transportation from 'assets/Transportation';
import Brawl from 'assets/Brawl';
import Clash from 'assets/Clash';
import Riot from 'assets/Riot';
import Strike from 'assets/Strike';
import Bomb from 'assets/Bomb';
import TerroristAttack from 'assets/TerroristAttack';
import TerroristArrest from 'assets/TerroristArrest';
import DustDevil from 'assets/DustDevil';
import OddEven from 'assets/OddEven'
import Road from 'assets/Road';
import ShipSinking from 'assets/ShipSinking';
import TrafficIncident from 'assets/TrafficIncident';
import Volcano from 'assets/Volcano';
import Protest from 'assets/Protest';
import Logo from 'assets/Logo';
import Danone from 'assets/Danone';
import Superindo from 'assets/Superindo';
import PnG from 'assets/PnG'
import Michelin from 'assets/Michelin'
import NavbarList from 'assets/NavbarList';
import MyIncidents from 'assets/MyIncidents';
import Categories from 'assets/Categories';

// eslint-disable-next-line import/prefer-default-export
export const assetIcons = {
  'Flight Accident': FlightIncident,
  'Odd-Even Policy': OddEven,
  'Road Closures': Road,
  'Ship Sinking': ShipSinking,
  'Traffic Accident': TrafficIncident,
  'Assault': Assault,
  'Drug trafficking': DrugTraf,
  'Others': Others,
  'Robbery': Robbery,
  'Dust Devil': DustDevil,
  'Earthquake': Earthquake,
  'Floods': Flood,
  'Hail Storm': Hailstorm,
  'Landslide': Landslide,
  'Tsunami': Tsunami,
  'Volcanic Eruption': Volcano,
  'Fire': Fire,
  'Forest and Land Fire': Forest,
  'Industrial Pollution': IndustrialPollution,
  'International Travel Regulation': International,
  'PPKM Extension': PPKM,
  'Transportation Regulation': Transportation,
  'Brawl': Brawl,
  'Clash': Clash,
  'Protest/ Demonstration': Protest,
  'Riot': Riot,
  'Strike': Strike,
  'Bomb Attack/Explosion': Bomb,
  'Terrorist Attack': TerroristAttack,
  'Terrorist Suspect Arrest': TerroristArrest,
  'Logo' : Logo,
  'Danone' : Danone,
  'Superindo' : Superindo,
  'PnG' : PnG,
  'Michelin' : Michelin,
  'NavbarList' : NavbarList,
  'MyIncidents' : MyIncidents,
  'Categories' : Categories,
};

export const assetColors = {
  'Flight Accident': '#747587',
  'Odd-Even Policy': '#747587',
  'Road Closures': '#747587',
  'Traffic Accident': '#00559f',
  'Assault': '#00559f',
  'Drug trafficking': '#00559f',
  'Others': '#00559f',
  'Robbery': '#00559f',
  'Dust Devil': '#fbc267',
  'Earthquake': '#fbc267',
  'Floods': '#fbc267',
  'Hail Storm': '#fbc267',
  'Landslide': '#fbc267',
  'Tsunami': '#fbc267',
  'Volcanic Eruption': '#fbc267',
  'Fire': '#c36b49',
  'Forest and Land Fire': '#c36b49',
  'International Travel Regulation': '#782551',
  'PPKM Extension': '#782551',
  'Transportation Regulation': '#782551',
  'Brawl': '#007a61',
  'Clash': '#007a61',
  'Protest/ Demonstration': '#007a61',
  'Riot': '#007a61',
  'Strike': '#007a61',
  'Bomb Attack/Explosion': '#c30b2e',
  'Terrorist Attack': '#c30b2e',
  'Terrorist Suspect Arrest': '#c30b2e',
};

