/* eslint-disable default-case */

// eslint-disable-next-line consistent-return
const mapReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MAP_OPTIONS':
      return {
        ...state,
        options: action.payload,
      };
    case 'SET_MAP_RADIUS':
      return {
        ...state,
        alertRadius: action.payload,
      };
    case 'REMOVE_MAP_OPTIONS':
      return {
        options: '',
        alertRadius: 25,
        selectedCategories: [],
        date: {
          start: '',
          end: '',
        },
      };
    case 'SET_MAP_FILTER':
      return {
        ...state,
        selectedCategories: action.payload,
      };
    case 'REMOVE_MAP_FILTER':
      return {
        ...state,
        selectedCategories: [],
      };
    case 'SET_DATE_FILTER': return {
      ...state,
      date: {
        start: action.payload.start,
        end: action.payload.end,
      },
    };
    case 'REMOVE_DATE_FILTER':
      return {
        ...state,
        date: {
          start: new Date(),
          end: new Date(),
        },
      };
  }
};

export default mapReducer;
