import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import { MapContextProvider } from 'context/mapContext';
import { UserContextProvider } from 'context/userContext';
import { ModalContextProvider } from 'context/modalContext';
import { theme } from 'utils/theme';
import './font/Gnuolane.otf';

ReactDOM.render(
  <React.StrictMode>
      <ChakraProvider
          theme={theme}>
          <UserContextProvider>
              <ModalContextProvider>
                  <MapContextProvider>
                      <App/>
                  </MapContextProvider>
              </ModalContextProvider>
          </UserContextProvider>
      </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
