// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import {
  Flex, Box, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  CircularProgress,
} from '@chakra-ui/react';
// import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import Sidebar from 'components/inboard/sidebar';
import { useUserContext } from 'context/userContext';
import { useModalContext } from 'context/modalContext';
import MapSidebar from 'components/inboard/map/sidebar';
import jwt_decode from 'jwt-decode'


const InboardLayout = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { dispatch: userDispatch } = useUserContext();
  const { dispatch: modalDispatch } = useModalContext();
  const { state: modalState } = useModalContext();
  const [showModal, setShowModal] = useState(false);
  const router = useLocation()


  useEffect(() => {
    setLoading(true);
    const cookies = Cookies.get('user')
    if (cookies) {
      const decoded = jwt_decode(cookies)
      userDispatch({
        type: 'STORE_USER',
        payload: {
          uid: decoded.id,
          email: decoded.email,
          role: decoded.role,
        },
      });
    }
    setTimeout(()=> {
      setLoading(false);
    }, 2000)
  }, []);

  useEffect(() => {
    setShowModal(modalState.modal.show);
  }, [modalState.modal.show]);


  const closeModal = () => {
    setShowModal(false);
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <Flex w="100" style={{ minHeight: '100vh', overflow: 'auto' }}>
      {/* Sidebar */}
      <Box px="3" pos="sticky" top="0">
        <Sidebar />
      </Box>
      {/* Content */}
      <Box flex="3" p="6" alignSelf="flex-start" height="100vh">
        {
          loading ? <>Loading...</> : children
        }
      </Box>
      {
        router.pathname.includes('map') && <Box pr="3" pos="sticky" top="0">
          <MapSidebar />
        </Box>
      }
      {/* Footer */}
      <Modal isOpen={showModal} onClose={closeModal} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalState.modal.title}</ModalHeader>
          {
            modalState.modal.status !== 'LOADING'
            && <ModalCloseButton />
          }
          <ModalBody>
            {
              modalState.modal.status === 'LOADING'
              && (
                <Flex alignItems="center" justifyContent="center" mb="8">
                  <CircularProgress isIndeterminate />
                </Flex>
              )
            }
            <Text>
              {modalState.modal.message}
            </Text>
          </ModalBody>

          <ModalFooter>
            {
              modalState.modal.status !== 'LOADING'
              && (
                <Button colorScheme="blue" onClick={closeModal}>
                  Close
                </Button>
              )
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default InboardLayout;
