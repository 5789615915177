import React from 'react';
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import TextField from 'components/inboard/base/TextField';
import TextareaField from 'components/inboard/base/TextareaField';
import ImageField from 'components/inboard/base/ImageField';
import SelectField from 'components/inboard/base/SelectField';
import { createSubCategory } from 'api/subcategories';
import { useUserContext } from 'context/userContext';
import { useModalContext } from 'context/modalContext';
// import { allCategories, subCategories } from 'data/categories';
import { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";


function SubCategoryForm(props) {
  const { state } = useUserContext();
  const { dispatch } = useModalContext();
  const { subCategory, refreshCallback } = props;
  const [visible, setVisible] = useState(false)
  const [category, setCategory] = useState()

  const formAttributes = [
    {
      name: 'name',
      id: 'name',
      labelName: 'Sub-category Name',
      placeholder: 'Please input Sub-category name',
      type: 'text',
    },
    {
      name: 'category',
      id: 'category',
      labelName: 'Category',
      placeholder: 'Please input Category',
      type: 'select',
    },
    {
      name: 'new_category',
      id: 'new_category',
      labelName: 'New Category Name',
      placeholder: 'Please input New Category Name',
      type: 'text-hidden',
    },
    {
      name: 'image',
      id: 'image',
      labelName: 'Image',
      placeholder: 'Please input image',
      type: 'file',
    }
  ];

  const validate = Yup.object().shape({
    name: Yup.string().required('Required'),
    // category: Yup.string().required('Required'),
    image: Yup.string().required('Required'),
    new_category: visible ? Yup.string().required() : Yup.string(),

  });

  const mapFormAttributes = (values) => formAttributes.map((attribute) => {
    if (attribute.type === 'text') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <TextField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
            />
          )}
        </Field>
      );
    }
    if (attribute.type === 'textarea') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <TextareaField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
            />
          )}
        </Field>
      );
    } 
    if (attribute.type === 'select') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <SelectField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
              options={props.listCategory}
              setSelectedOption = {setSelectedOption}
              selectedValue = {category}
            />

          )}
        </Field>
      );
    }
    if (attribute.type === 'file') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <ImageField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
            />
          )}
        </Field>
      );
    }
    if (attribute.type === 'text-hidden') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <TextField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
              visibility={visible}
            />
          )}
        </Field>
      );
    }
  });
  
  const changeVisibility = (visible) => {
    setVisible(visible)
  }

  const setSelectedOption = (value) => {
    if(value === 'new category') {
      changeVisibility(true)
    }
    else {
      changeVisibility(false)
    }
    setCategory(value);
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
  return (

    <Formik
      initialValues={{
        name: subCategory?.subCategoryName || '',
        category: '',
        image:  undefined,
      }}
      validationSchema={validate}
      // eslint-disable-next-line no-unused-vars
      onSubmit={async (values, { resetForm }) => {

        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'LOADING',
            message: 'Trying to get your data...',
            title: 'Loading...',
          },
        });
        let imageBase64 = await getBase64(values.image);

        
        if(category === "new category") setCategory("");
          const newSubCategory = {
            subCategoryName: values.name,
            categoryId: category,
            category: values.new_category,
            image: imageBase64
          };
          createSubCategory(newSubCategory).then((res) => {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'SUCCESS',
                message: 'Successfully Added Sub-category',
                title: 'Success!',
              },
            });
            resetForm();
            setCategory("");
            window.location.reload(false);
          }).catch((err) => {
            console.log(err);
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'FAILED',
                message: err.response.data.message,
                title: 'Failed!',
              },
            });
          });
        // }
      }}
    >


      {({ values }) => (
        <Form>
    {mapFormAttributes(values)}

          <Button
            isFullWidth
            my={3}
            bg="#18587c"
            type="submit"
          >
            <Text color="white">Submit</Text>
            
          </Button>
        </Form>
        
      )}
    </Formik>
  );
}

export default SubCategoryForm;
