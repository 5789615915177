import React from 'react';

import {
  FormControl, FormLabel, Select, FormErrorMessage,
} from '@chakra-ui/react';

const mapOptions = (options) => options.map((option) => (
  <option key={option.value} value={option.value}>{option.text}</option>
));

function SelectField({
  form, id, labelName, options, field, placeholder, setSelectedOption = () => {}, selectedValue
}) {
  return (
  // eslint-disable-next-line no-bitwise
    <FormControl isInvalid={(form.errors[id] && form.touched[id])} mt={4}>
      <FormLabel htmlFor={id}>{labelName}</FormLabel>
      <Select {...field} id={id}  
      onChange={e =>{form.handleChange(e); setSelectedOption(e.target.value)}} value = {selectedValue}
      >
      <option selected hidden disabled value="">{placeholder}</option>
        {
            options.length > 0 && mapOptions(options)
        }
      </Select>
      {' '}
      <FormErrorMessage>
        {labelName}
        {' '}
        {form.errors[id]}
      </FormErrorMessage>
    </FormControl>
  );
}

export default SelectField;
