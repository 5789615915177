import React from 'react';
import {
  Heading, Text, Table, Tbody, Td, Tr, Stack,
} from '@chakra-ui/react';
import InboardLayout from 'layout/InboardLayout';
import { useUserContext } from 'context/userContext';
import ModalChangePassword from 'components/inboard/user/ModalChangePassword';
import SEO from 'components/SEO'

function Profile() {
  const { state } = useUserContext();

  return (
    <div>
      <SEO title="Profile - Asset Monitoring" />
      <Heading as="h3">
        Profile Page
      </Heading>
      <Table variant="simple" mt="6">
        <Tbody>
          <Tr>
            <Td>
              <Text>
                Email:
              </Text>
            </Td>
            <Td>
              {state.user?.email || ''}
            </Td>
          </Tr>
          <Tr>
            <Td>
              <Text>
                Role:
              </Text>
            </Td>
            <Td>
              <Text>
                {state.user?.role || ''}
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Stack direction="row" spacing={2} mt="6">
        <ModalChangePassword />
      </Stack>
    </div>
  );
}
Profile.layout = function getLayout(page) {
  return (
    <InboardLayout>
      {page}
    </InboardLayout>
  );
};

export default Profile;
