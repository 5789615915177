import React from 'react';
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Switch,
  Text,
  Icon,
  Textarea,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import TextField from 'components/inboard/base/TextField';
import TextareaField from 'components/inboard/base/TextareaField';
import SelectField from 'components/inboard/base/SelectField';
import { createIncident, updateIncident } from 'api/incidents';
import { useUserContext } from 'context/userContext';
import { useModalContext } from 'context/modalContext';
import { allCategories } from 'data/categories';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { FormLabel, Flex, Box } from '@chakra-ui/react'
import "react-datepicker/dist/react-datepicker.css";
import { getCategoriesWithSubcategories } from 'api/categories';


function IncidentForm(props) {
  const { state } = useUserContext();
  const { dispatch } = useModalContext();
  const { incident, refreshCallback } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date())
  const [visibile, setVisibile] = useState(false)
  const [ categories, setMyCategories] = useState([]);
  const [ subCategories, setMySubCategories] = useState([]);

  const changeVisibility = () => {
    setVisibile(!visibile)
  }

  const formAttributes = [
    {
      name: 'name',
      id: 'name',
      labelName: 'Incident Name',
      placeholder: 'Please input name',
      type: 'text',
    },
    {
      name: 'description',
      id: 'description',
      labelName: 'Incident Description',
      placeholder: 'Please input description',
      type: 'textarea',
    },
    {
      name: 'category',
      id: 'category',
      labelName: 'Incident Category',
      placeholder: 'Please input category',
      type: 'select',
    },
    {
      name: 'subCategory',
      id: 'subCategory',
      labelName: 'Incident Sub Category',
      placeholder: 'Please input sub category',
      type: 'subSelect',
    },
    {
      name: 'originalSource',
      id: 'originalSource',
      labelName: 'Original Source',
      placeholder: 'Please input original source',
      type: 'text',
    },
    {
      name: 'latitude',
      id: 'latitude',
      labelName: 'Latitude',
      placeholder: 'Please input latitude',
      type: 'text',
    },
    {
      name: 'longitude',
      id: 'longitude',
      labelName: 'Longitude',
      placeholder: 'Please input longitude',
      type: 'text',
    },
  ];

   const formDeath = [
    {
      name: 'description',
      ml: '2',
      id: 'description',
      labelName: 'Injuries',
      placeholder: 'Please input description',
      type: 'text',
    },
    {
      name: 'category',
      id: 'category',
      labelName: 'Deaths',
      placeholder: 'Please input category',
      type: 'text',
    },
  
   ];

   const getAllCategory = () => {
    if (state.user) {
      getCategoriesWithSubcategories().then((res) => {
        let data = res.data.data.categories.map(category => ({
          id:category.id,
          text: category.name,
          value: category.id, 
          // selected: category.name,
          subcategory: category.subcategory
        }));
        let subcategories = [];
        data.map(category => category.subcategory.map(subcategory => {
          subcategories[category.id] = subcategories[category.id] || []
            subcategories[category.id].push({
              id:subcategory.id,
              text: subcategory.name,
              value: subcategory.name, 
            })
          })
        )
        setMySubCategories(subcategories);
        setMyCategories(data);
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  const validate = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    subCategory: Yup.string().required('Required'),
    originalSource: Yup.string().required('Required'),
    latitude: Yup.number().required('Required'),
    longitude: Yup.number().required('Required').typeError('Value must be a decimal e.g. 22.00'),
  });

  const mapFormAttributes = (values) => formAttributes.map((attribute) => {
    if (attribute.type === 'text') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <TextField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
            />
          )}
        </Field>
      );
    }
    if (attribute.type === 'textarea') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <TextareaField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
            />
          )}
        </Field>
      );
    } 
    if (attribute.type === 'select') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <SelectField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
              options={categories}
            />

          )}
        </Field>
      );
    }
    if (attribute.type === 'subSelect') {
      return (
        <Field name={attribute.name} key={attribute.id}>
          {({ field, form }) => (
            <SelectField
              key={attribute.id}
              id={attribute.name}
              form={form}
              field={field}
              labelName={attribute.labelName}
              placeholder={attribute.placeholder}
              // @ts-ignore
              options={subCategories[values.category] || []}
            />
          )}
        </Field>
      );
    }
  });

  const mapFromDeath = (values) => formDeath.map((deathAttribute) =>{
    if (deathAttribute.type === 'text') {
      return (
        <Field name={deathAttribute.name} key={deathAttribute.id}>
          {({ field, form }) => (
            <TextField
              key={deathAttribute.id}
              id={deathAttribute.name}
              form={form}
              ml={'2'}
              field={field}
              labelName={deathAttribute.labelName}
              placeholder={deathAttribute.placeholder}
            />
          )}
        </Field>
      );
    } if (deathAttribute.type === 'select') {
      return (
        <Field name={deathAttribute.name} key={deathAttribute.id}>
          {({ field, form }) => (
            <SelectField
              key={deathAttribute.id}
              id={deathAttribute.name}
              form={form}
              field={field}
              labelName={deathAttribute.labelName}
              placeholder={deathAttribute.placeholder}
              options={categories}
            />

          )}
        </Field>
      );
    }
    if (deathAttribute.type === 'subSelect') {
      return (
        <Field name={deathAttribute.name} key={deathAttribute.id}>
          {({ field, form }) => (
            <SelectField
              key={deathAttribute.id}
              id={deathAttribute.name}
              form={form}
              field={field}
              labelName={deathAttribute.labelName}
              placeholder={deathAttribute.placeholder}
              // @ts-ignore
              options={categories[values.category] || []}
            />
          )}
        </Field>
      );
    }
  });


  useEffect(() => {
    getAllCategory();
    if (incident?.reportedDate != null && incident.reportedDate != '') {
      const date = new Date(incident.reportedDate)
      setStartDate(date)
    }
    if (incident?.endedDate != null && incident.endedDate != '') {
      setVisibile(true)
      const date = new Date(incident.endedDate)
      setEndDate(date)
    }
  }, [])

  return (

    <Formik
      initialValues={{
        name: incident?.nameIncident || '',
        description: incident?.descriptionIncident || '',
        category: incident?.categoryIncident || '',
        subCategory: incident?.subCategoryIncident || '',
        originalSource: incident?.originalSourceIncident || '',
        longitude: incident?.longitude || '',
        latitude: incident?.latitude || '',
      }}
      validationSchema={validate}
      // eslint-disable-next-line no-unused-vars
      onSubmit={(values, { resetForm }) => {

        dispatch({
          type: 'SHOW_MODAL',
          payload: {
            show: true,
            status: 'LOADING',
            message: 'Trying to get your data...',
            title: 'Loading...',
          },
        });

        if (incident && incident.id) {
          const newValues = {
            id: incident.id,
            nameIncident: values.name,
            descriptionIncident: values.description,
            categoryIncident: values.category,
            subCategoryIncident: values.subCategory,
            originalSourceIncident: values.originalSource,
            longitude: Number(values.longitude),
            latitude: Number(values.latitude),
            reportedDate: startDate,
            endedDate: visibile ? endDate : null
          };
          updateIncident(incident.id, newValues).then((res) => {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'SUCCESS',
                message: 'Successfully Updated Incident',
                title: 'Success!',
              },
            });
            refreshCallback();
          }).catch((err) => {
            console.log(err);
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'FAILED',
                message: err.response.data.message,
                title: 'Failed!',
              },
            });
          });
        } else {
          const newIncident = {
            nameIncident: values.name,
            descriptionIncident: values.description,
            categoryIncident: values.category,
            subCategoryIncident: values.subCategory,
            originalSourceIncident: values.originalSource,
            longitude: Number(values.longitude),
            latitude: Number(values.latitude),
            adminId: state.user?.uid,
            reportedDate: startDate,
            endedDate: visibile ? endDate : null
          };
          createIncident(newIncident).then((res) => {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'SUCCESS',
                message: 'Successfully Added Incident',
                title: 'Success!',
              },
            });
            resetForm();
          }).catch((err) => {
            console.log(err);
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                show: true,
                status: 'FAILED',
                message: err.response.data.message,
                title: 'Failed!',
              },
            });
          });
        } //
      }}
    >


      {({ values }) => (
        <Form>
    {mapFormAttributes(values)}
      
      
          <FormLabel htmlFor="start-date" mt="3">Start Date</FormLabel>
          <DatePicker
            id="start-date"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeInput
            dateFormat="dd/MM/yyyy h:mm aa"
          />
          {/* <Switch id='email-alerts' /> */}
          <Flex alignItems="center">
            <FormLabel htmlFor="start-date" mt="3">End Date</FormLabel>
            <Switch id='end date' onChange={changeVisibility} defaultChecked={incident?.endedDate != null && incident.endedDate != '' ? true : false} />
          </Flex>
          {
            visibile && (
              <DatePicker
                id="start-date"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeInput
                dateFormat="dd/MM/yyyy h:mm aa"

              />
            )
          }

          <Button
            isFullWidth
            my={3}
            bg="#18587c"
            type="submit"
          >
            <Text color="white">Submit</Text>
            
          </Button>
        </Form>
        
      )}
    </Formik>
  );
}

export default IncidentForm;
