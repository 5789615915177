/* eslint-disable default-case */

// eslint-disable-next-line consistent-return
const modalReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        modal: action.payload,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modal: {
          show: false,
          status: '',
          title: '',
          message: '',
        },
      };
  }
};

export default modalReducer;
