import { axiosClient, axiosFile } from 'api/config';

export const getMyIncidents = (adminId, data) => axiosClient.post(`/incident`, data);
export const getAllIncidents = (data) => axiosClient.post('/incident', data);
export const createIncident = (data) => axiosClient.post('/createIncident', data);
export const updateIncident = (incidentId, data) => axiosClient.put(`/incident/${incidentId}`, data);
export const deleteIncident = (incidentId) => axiosClient.delete(`/incident/${incidentId}`);
export const downloadIncidents = (data) => axiosFile.post('/exportIncident', data);
export const getMyIncidentsCount = (adminId, data) => axiosClient.post(`/incidentCount/${adminId}`, data);
export const getIncidentCountPeriod = (adminId, data) => axiosClient.post(`/incidentCountPeriod/${adminId}`, data);
