import { useEffect } from 'react';
import React from 'react';

import Cookie from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router'
import { logout } from 'api/auth';

function Logout() {
  const router = useHistory();
  useEffect(() => {
    console.log("is this doing something")
    logout().then(() => {
      Cookie.remove('user');
      router.replace('/')
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
      <div></div>
  );
}

export default Logout;
