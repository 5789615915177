import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    IconButton,
    useDisclosure,
    Text
} from '@chakra-ui/react'
import { FiTrash } from 'react-icons/fi';
import { deleteUser } from 'api/auth';
import { useModalContext } from 'context/modalContext';



function ModalDeleteUser(props) {
    const { dispatch } = useModalContext();
    const { email, uid, refreshUsers } = props
    const { isOpen, onOpen, onClose } = useDisclosure()
    const deleteUserHandler = async () => {
        dispatch({
            type: 'SHOW_MODAL',
            payload: {
                show: true,
                status: 'LOADING',
                message: 'Trying to get your data...',
                title: 'Loading...',
            },
        });
        try {
            await deleteUser(uid)
            dispatch({
                type: 'SHOW_MODAL',
                payload: {
                    show: true,
                    status: 'SUCCESS',
                    message: 'Successfully Deleted User',
                    title: 'Success!',
                },
            });
            refreshUsers()
            onClose()
        } catch (err) {
            dispatch({
                type: 'SHOW_MODAL',
                payload: {
                    show: true,
                    status: 'FAILED',
                    
                    message: err.response.data.message,
                    title: 'Failed!',
                },
            });
        }
    }
    return (
        <>
            <IconButton aria-label='trash' icon={<FiTrash />} ml="6" onClick={onOpen} colorScheme="red">
            </IconButton>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            Are you sure you want to delete {email}?
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='teal' variant="outline" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme="red" onClick={deleteUserHandler} >Delete</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ModalDeleteUser
