import React from 'react';
import { Helmet } from 'react-helmet'

function SEO(props) {
  const { title, content, meta = [] } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={content} />
      {
          meta.length > 0 && meta.forEach((tag) => (
            <meta name={tag.name} content={tag.content} />
          ))
      }
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
}

export default SEO;
