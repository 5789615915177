import axiosClient from 'api/config';

export const getUser = () => axiosClient.get('/users');
export const createUser = (data) => axiosClient.post('/user', data);
export const deleteUser = (id) => axiosClient.delete(`/user/${id}`)
export const login = (credentials) => axiosClient.post('/login', credentials);
export const changePassword = (id, payload) => axiosClient.put(`/change-password/${id}`, payload);
export const refreshToken = () => axiosClient.get('/refresh-token');
export const logout = () => axiosClient.delete('/refresh-token');
export const resetPassword = (data) => axiosClient.post('/reset-password', data);
export const changeResetPassword = (userId, data) => axiosClient.put(`/change-password-reset/${userId}`, data);