import React from 'react'
import { Redirect } from 'react-router';
import Cookies from 'js-cookie';
import { Route, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode'

const ProtectedRoute = (props) => {
  const location = useLocation()
  const pathName = location.pathname.toString();
  const { path, exact, component } = props;
  const isLoading = false
  if(isLoading){
    return (<div>Authenticating...</div>)
  } else {
    const accessToken = Cookies.get('user');
    const hasJwt = accessToken !== '' && accessToken !== undefined
    console.log(accessToken)
    if(!hasJwt){
      return (
          <Redirect to="/"/>
      )
    }
    let role = '';
    if (pathName.includes('super-admin')) {
      role = 'super_admin';
    } else if (pathName.includes('admin')) {
      role = 'admin';
    }
    if (role !== ''){
      const decoded = jwt_decode(accessToken)
      if (decoded.role !== role) {
        return (
            <Redirect to="/dashboard/profile" />
        )
      }
    }
      return (
          <Route
              path={path}
              exact={exact}
              component={component}
          >
          </Route>
      )
  }
}

export  default ProtectedRoute