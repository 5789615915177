import React, { useEffect, useState } from 'react'
import './App.css';
import Map from 'pages/dashboard/map'
import InboardLayout from 'layout/InboardLayout'
import OutboardLayout from 'layout/OutboardLayout'
import Login from 'components/outboard/Login'
import Profile from 'pages/dashboard/profile'
import AddIncident from 'pages/dashboard/admin/add-incident'
import AddSubCategory from 'pages/dashboard/admin/add-categories'
import MyIncident from 'pages/dashboard/admin/my-incident'
import ListUser from 'pages/dashboard/super-admin/list-users'
import ProtectedRoute from 'components/Protected'
import { useUserContext } from 'context/userContext'
import 'styles/globals.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';
import Logout from 'pages/dashboard/logout'

function App() {
    const { state, dispatch } = useUserContext()
    const accessToken = Cookies.get('user');
    const [loading, setLoading] = useState(false)

    useEffect(()=> {
        setLoading(true)
        if(accessToken != '' && accessToken != undefined){
            const decoded = jwt_decode(accessToken)
            console.log(decoded.id)
            dispatch({
                type: "STORE_USER",
                payload: {
                    uid: decoded.id,
                    email: decoded.email,
                    role: decoded.role
                }
            })
        }
        setLoading(false)
        console.log(state.user)
    }, [])

  return (
      <Router>
         <Switch>
             <Route exact path="/">
                 <OutboardLayout>
                     <Login />
                 </OutboardLayout>
             </Route>
             <Route path="/dashboard">
                <InboardLayout>
                    <ProtectedRoute path="/dashboard/map" component={Map}/>
                    <ProtectedRoute path="/dashboard/profile" component={Profile}/>
                    <Route path="/dashboard/admin">
                        <ProtectedRoute path="/dashboard/admin/add-incident" component={AddIncident}/>
                        <ProtectedRoute path="/dashboard/admin/my-incident" component={MyIncident}/>
                        <ProtectedRoute path="/dashboard/admin/add-subcategory" component={AddSubCategory}/>
                    </Route>
                    <Route path="/dashboard/super-admin">
                        <ProtectedRoute path="/dashboard/super-admin/list-users" component={ListUser}/>
                    </Route>
                    <Route path="/dashboard/logout">
                        <Logout />
                    </Route>
                </InboardLayout>
             </Route>
         </Switch>
      </Router>
  );
}

export default App;
